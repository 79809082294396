import ReactDOM from 'react-dom';

export const scroll = (y_coord = 0) => {
  const distance_to_top = document.documentElement.scrollTop;
  const scrollUp = y_coord < distance_to_top;
  const animated = () => {
    // n = n + 1;
    // console.log(`y thu_${n} ==> ${y_coord}`);   
    const distance_to_top = document.documentElement.scrollTop;
    if (scrollUp) {
      if (distance_to_top > y_coord) {
        window.requestAnimationFrame(animated);
        window.scrollTo(0, distance_to_top - distance_to_top / 10);
      }
    } else {
      if (distance_to_top < y_coord) {
        window.requestAnimationFrame(animated);
        if (distance_to_top === 0) {
          window.scrollTo(0, y_coord / 10);  
        } else {
          window.scrollTo(0, distance_to_top + distance_to_top / 10);
        }
      }
    }
  }
  animated()
};

export const scrollElement = (virtual_dom) => {
  const node_dom = ReactDOM.findDOMNode(virtual_dom);
  //console.log('node_dom', node_dom);
  
  const width_screen = window.innerWidth;
  const height_header = width_screen >= 965 ? 160 : 60;
  const distance = node_dom.getBoundingClientRect().top + window.scrollY - height_header;
  scroll(distance);
}
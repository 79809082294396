import { FreeRequestDataInitial } from 'constants/FreeRequestDataInitial';

const data = FreeRequestDataInitial

const FormRequestData = (state = data, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_FORM_REQUEST':
      return { ...state, ...action.data };
    case 'RESET_DATA_FORM_REQUEST':
      return data
    case 'UPDATE_ZIPCODE':
      return {
        ...state,
        zipcode: {
          ...action.data
        }
      };
    case 'UPDATE_DESIRED':
      return {
        ...state,
        desired: {
          ...action.data
        }
      };
    case 'UPDATE_ERRS_FORM_REQUEST':
      return {
        ...state,
        errs: {
          ...state.errs,
          ...action.data.errs
        }
      };
    default:
      return state;
  }
};

export default FormRequestData;
import styled from 'styled-components';

export const Title = styled.div`
  font-size: 18px;
  color: #e95513;
`
export const Content = styled.div`
  padding : ${props => props.padding || "20px 0"};
  font-size: 15px;
  color: #000000;
`
import React from 'react';
import { Route, Link } from "react-router-dom";

import menus from 'views/components/Menu';
import NavbarWrapper from './styled';

class OldSchoolMenuLink extends React.Component {
  render() {
    const { label, to, activeOnlyWhenExact } = this.props;
    return (
      <Route
        path={to}
        exact={activeOnlyWhenExact}
        children={({ match }) => (
          <Link className={`${match ? "active" : ""} `} onClick={this.scrollToTop} to={to} >{label}</Link>
        )}
      />
    )
  }
}

class Navbar extends React.Component {
  render() {
    return (
      <NavbarWrapper align="middle" type="flex" justify="end" style={{marginTop: "7px"}}>
        {
          menus.map((menu, i) => {
            return (
              <OldSchoolMenuLink
                key={i}
                label={menu.title}
                to={menu.to}
              />
            )
          })
        }
      </NavbarWrapper>
    )
  }
}

export default Navbar;

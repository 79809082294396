import React from 'react';
import { Row } from "antd";

import Heading from 'views/components/Heading';
import Loading from 'views/components/Loading';
import { Container } from 'views/commons/styles';
import { fetchBanners } from 'apis';
import { BannerItem } from './styled';

class Banners extends React.Component {

  state = {
    is_fetching: true,
    banners: [], 
  };

  componentDidMount() {
    fetchBanners().then((data) => {
      this.setState({
        is_fetching: false,
        banners: data.banners,
      })
    })
  }

  render() {
    const { is_fetching, banners } = this.state;
    return (
      <Container>
        <Heading>バナー</Heading>
        {
          is_fetching
          ? <Loading amount={3} />
          : <Row type="flex">
              {
                banners.map((banner, i) => {
                  return (
                    <BannerItem key={i} xs={12} lg={8}>
                      <a href={banner.url} target="_blank" rel="noopener noreferrer">
                        <img src={banner.image_url} alt={banner.description}/>
                      </a>
                    </BannerItem>
                  );
                })
              }
            </Row>
        }
      </Container>
    )
  }
}

export default Banners;
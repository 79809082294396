import React from 'react';
import SearchModal from 'views/components/SearchModal';
import Anchor from 'views/components/Anchor/Top';
import NewFranchises from './NewFranchises';
import FranchisesSettingSearch from 'views/components/FranchisesSettingSearch';
import RandomeFranchises from './RandomeFranchises';
import Banners from './Banners';

class Home extends React.Component {
  render() {
    return (
      <>
        <SearchModal />
        <Anchor />
        <NewFranchises />
        <RandomeFranchises />
        <Banners/>
        <FranchisesSettingSearch heading="フランチャイズを探す" background="#e6e6e6" />
      </>
    )
  }
}

export default Home;

const data = {
  features: [],
  areas: [],
  targets: []
}
const FranchisesSearchCondition = (state = data, action) => {
  switch (action.type) {
    case 'UPDATE_SEARCH_CONDITION':
      return { 
        ...data,
        ...action.data
      };
    default:
      return state;
  }
};

export default FranchisesSearchCondition;
const post = {};

const PostDetail = (state = post, action) => {
  switch (action.type) {
    case 'UPDATE_POST_DETAIL':
      return { ...action.data };
    default:
      return state;
  }
};


export default PostDetail;
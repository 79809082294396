import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Instruction from 'views/components/Form/Instruction';
import Field from 'views/components/Form';
import { fields } from 'constants/InquiryFields';
import {success} from 'views/components/Success';
import { validate } from 'helpers/Validate';
import { scrollElement } from 'helpers/SmoothScroll';
import { updateErrsForm } from 'actions';
import {postDataInquiry} from 'apis';

import { Container, PrimaryButton } from 'views/commons/styles';
import { GroupButton } from './styled';

class InquiryPage extends React.Component {

  handleSubmit = () => {
    const {data} = this.props;
    let errs = {};
    fields.forEach(field => {
      const { name } = field;
      let value = data[name];
      switch (name) {
        case 'email_confirm':
          value = {
            email: data.email,
            email_confirm: value
          };
          break;
        default:
          break
      }
      errs[name] = validate(field, value)
    });
    this.props.updateErrsForm({errs, reducer:"INQUIRY"});

    // Check if there are any errors in the field
    const name_errs = Object.keys(errs).filter(name => Boolean(errs[name]));
    if (name_errs.length) {
      const name_err = name_errs[0];
      const field_err = this.refs[name_err];
      scrollElement(field_err);
    }
    else{
      const { 
        errs, 
        reducer,
        email_confirm,
        ...dataSubmit 
      } = data;
      postDataInquiry(dataSubmit);
      success(() => this.props.history.push('/'));
    }
  };
  render() {
    const { data } = this.props;
    const {errs} = data;
    return (
      <>
        <Instruction>
          必要事項を記入し、下記の利用規約をご確認後、「規約に同意して確認画面へ」ボタンを押してください。
        </Instruction>
        <div style={{ background: "#f2f2f2", padding: "30px 0" }}>
          <Container>
            {
              fields.map((field, i) => {
                return (
                  <Field
                    key={i}
                    reducer="INQUIRY"
                    align={`${field.tag === "textarea" ? "top" : "middle"}`}
                    field={field}

                    err={errs[field.name]}
                    ref={field.name}
                  />
                )
              })
            }
            <div style={{textAlign: "center", margin: "20px auto"}}>
              <Link to="/policy">個人情報保護方針</Link>
              <b>をご確認の上、「利用規約に同意して確認画面へ」ボタンを押して次の画面へ進んでください。</b>
            </div>
            <GroupButton>
              <PrimaryButton width="120px" background="#b3b3b3">
                もどる
              </PrimaryButton>
              <PrimaryButton width="360px" onClick={this.handleSubmit}>
                  利用規約に同意して確認画面へ
              </PrimaryButton>
            </GroupButton>
          </Container>
        </div >
      </>
    )
  }
}

const mapStoreToProps = store => {
  return {
    data: store.InquiryData
  };
};
export default connect(
  mapStoreToProps,
  {updateErrsForm}
)(InquiryPage);


const menus = [
  {
    title: 'よくあるご質問',
    to: '/qa',
    activeOnlyWhenExact: false
  },
  {
    title: 'お問い合わせ',
    to: '/inquiry',
    activeOnlyWhenExact: false
  },
  {
    title: '掲載希望の方へ',
    to: '/company_request',
    activeOnlyWhenExact: false
  },
  {
    title: '会社案内',
    to: '/about',
    activeOnlyWhenExact: false
  }
]

export default menus;

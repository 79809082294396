import React, { Component } from 'react';
import { Step, StepWrapper, StepIndex } from './styled';

const steps = [
  "情報入力",
  "内容確認",
  "メール確認",
  "完了"
]

class Stepper extends Component {
  state = {
    curStep: 1
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.curStep !== this.state.curStep) {
      this.setState({ curStep: nextProps.curStep })
    }
  }

  render() {
    const { curStep } = this.state;
    return (
      <StepWrapper type="flex" justify="space-between">
        {
          steps.map((step, i) => {
            return (
              <Step key={i} className={`${curStep === i + 1 ? "active" : null}`}>
                <StepIndex>{`STEP${i + 1} . `}</StepIndex>
                <span>{`${step}`}</span>
              </Step>
            )
          })
        }
      </StepWrapper>
    )
  }
}

export default Stepper;
const posts = [];

const PostsRelated = (state = posts, action) => {
  switch (action.type) {
    case 'UPDATE_POSTS_RELATED':
      return [...action.data ];
    default:
      return state;
  }
};


export default PostsRelated;
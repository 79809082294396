export const FreeRequestDataInitial = {
  company_name: "",
  name: "",
  name_kata: "",
  birthday: "",
  phone: "",
  email: "",
  email_confirm: "",
  zipcode: {
    zipcode1: "",
    zipcode2: "",
    value: ""
  },
  prefecture: "",
  city: "",
  address: "",
  apartment: "",
  comment: "",
  desired: {
    features: [],
    areas: [],
    target: []
  },
  errs: {
    company_name: "",
    name: "",
    name_kata: "",
    birthday: "",
    phone: "",
    email: "",
    email_confirm: "",
    zipcode: "",
    prefecture: "",
    city: "",
    address: "",
    apartment: "",
    comment: "",
    desired: "",
  }
}
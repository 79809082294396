import styled from 'styled-components';
import { Checkbox } from "antd";
const CheckboxGroup = Checkbox.Group;

export const Wrapper = styled.div`
  margin-bottom: 30px;
`

export const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.color_title };
  border-bottom: ${props => props.border_title };
  padding: ${props => props.padding_title };
  margin: ${props => props.margin_title };
`
export const StyledCheckBox = styled(CheckboxGroup)`
  line-height: 2.3em !important;
  padding-left: 20px !important;
  span{
    color: ${props => props.color_label };
    span{
      border: ${props => props.border_checkbox }
    }
  }
`
import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { Breadcrumb } from 'antd';
import { connect } from "react-redux";

import { page_defines } from 'constants/PageDefine';
import { identifySearchType } from 'helpers/IdentifySearchType';
import { Container } from 'views/commons/styles';
import { BreadcrumbItem } from './styled';

class BreadcrumbComponent extends React.Component {
  render() {

    const { location, search_types, search_conditions, title } = this.props;
    let path_name = location.pathname.substring(1);
    path_name = path_name.replace(/\/page=\d+$/i, '')

    let breadcrumb;

    const franchises_search_format = /^franchises\/search$/i;
    const detail_page_format = /\/id=\d+$/i;

    const is_franchises_search_page = franchises_search_format.test(path_name);
    const is_detail_page = detail_page_format.test(path_name);

    breadcrumb = !page_defines[path_name] ? page_defines['not-found']['breadcrumb'] : page_defines[path_name].breadcrumb;

    if (is_franchises_search_page) {
      breadcrumb = identifySearchType(search_types, search_conditions)
    };

    if (is_detail_page) {
      breadcrumb = title || page_defines['not-found']['breadcrumb']
    };

    return (
      <Container>
        {
          !!path_name && <Breadcrumb separator=">" style={{ margin: "15px 0" }}>
            <BreadcrumbItem>
              <Link to="/">フード・フランチャイズ・ナビ麺</Link>
            </BreadcrumbItem>

            {
              is_franchises_search_page && <BreadcrumbItem>
                <Link to="/franchises/setting">フランチャイズを探す</Link>
              </BreadcrumbItem>
            }

            <BreadcrumbItem>
              <Link to={`/${path_name}`}>{breadcrumb}</Link>
            </BreadcrumbItem>

          </Breadcrumb>
        }
      </Container>
    )
  }
}

const mapStoreToProps = store => {
  return {
    search_types: store.search_types,
    search_conditions: store.search_conditions,
    title: store.PostDetail.title
  };
};
export default withRouter(connect(mapStoreToProps)(BreadcrumbComponent));

import React, { Component } from 'react';
import { Container } from 'views/commons/styles';

class Instruction extends Component {
  render() {
    return (
      <div style={{background:"#fff", paddingBottom:"25px"}}>
        <Container>
          {this.props.children}
        </Container>
      </div>
    )
  }
}

export default Instruction

import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'views/components/Heading';
import Loading from 'views/components/Loading';
import Franchises from 'views/components/ListPost/Franchises';
import {fetchRelatedCompanyCharacteristicFranchises} from 'apis';

class RelatedFranchiseList extends React.Component {
  static propTypes = {
    companyCharacteristicId: PropTypes.number,
  }

  state = {
    is_fetching: true,
    franchises: [],
  }

  componentDidMount() {
    const {companyCharacteristicId} = this.props;
    fetchRelatedCompanyCharacteristicFranchises(companyCharacteristicId, {per_page: 4}).then(data => {
      this.setState({
        is_fetching: false,
        franchises: data.franchises
      })
    })
  }

  render() {
    const {is_fetching, franchises} = this.state;
    return (
      <>
        <Heading>この企業のフランチャイズ募集</Heading>
        {
          is_fetching
          ? <Loading amount={4} />
          : <Franchises franchises={franchises} />
        }
      </>
    )
  }
}

export default RelatedFranchiseList;
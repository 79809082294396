import React from 'react';
import { Icon } from 'antd';

import Heading from 'views/components/Heading';
import Loading from 'views/components/Loading';
import Franchises from 'views/components/ListPost/Franchises';
import { Container, SeeMore } from 'views/commons/styles';
import { fetchFranchises } from 'apis';


class NewFranchises extends React.Component {

  state = {
    is_fetching: true,
    franchises: [], 
  };

  componentDidMount() {
    fetchFranchises({per_page: 4, order_field: "updated_at"}).then((data) => {
      this.setState({
        is_fetching: false,
        franchises: data.franchises,
      })
    })
  }

  render() {
    const { is_fetching, franchises } = this.state;
    return (
      <Container>
        <Heading>注目！フランチャイズ</Heading>
        {
          is_fetching
          ? <Loading amount={4} />
          : <Franchises franchises={franchises} />
        }
        <SeeMore to="/franchises">
          <Icon type="caret-right" />もっと見る
        </SeeMore>
      </Container>
    )
  }
}

export default NewFranchises;
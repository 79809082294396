import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {identifySearchType} from 'helpers/IdentifySearchType';
import { page_defines } from 'constants/PageDefine';

import {StyledIdentification} from './styled';

class Identification extends React.Component {
  render() {
    const { location, search_types, search_conditions, title } = this.props;
    let path_name = location.pathname.substring(1);
    path_name = path_name.replace(/\/page=\d+$/i, '')

    let page_title;
    
    const franchises_search_format = /^franchises\/search$/i;
    const detail_page_format = /\/id=\d+$/i;

    const is_franchises_search_page = franchises_search_format.test(path_name);
    const is_detail_page = detail_page_format.test(path_name);

    page_title = !page_defines[path_name] ? page_defines['not-found']['title'] : page_defines[path_name].page_title;

    if(is_franchises_search_page){
      page_title = identifySearchType(search_types, search_conditions)
    };

    if(is_detail_page){
      page_title = title || page_defines['not-found']['title']
    };

    return(
      <>
        {
          !!path_name ? <StyledIdentification>{page_title}</StyledIdentification> : null
        }
      </>
    )
  }
}

const mapStoreToProps = store => {
  return {
    search_types: store.search_types,
    search_conditions : store.search_conditions,
    title: store.PostDetail.title
  };
};

export default withRouter(connect(mapStoreToProps)(Identification));

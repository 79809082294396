import React from 'react';

class BusinessContent extends React.Component {
  render() {
    return (
      <div>
        <p>
          <img src="images/square-icon.png" alt="icon" />&nbsp;
          飲食店専門のフランチャイズ（FC）加盟開発事業
        </p>
        <p>
          <img src="images/square-icon.png" alt="icon" />&nbsp;
          飲食店の経営
        </p>
        <p>
          <img src="images/square-icon.png" alt="icon" />&nbsp;
          飲食店専門ＩＴソリューション事業
        </p>
      </div>
    )
  }
}

export default BusinessContent;

const data = {
  company_name: "",
  name: "",
  name_kata: "",
  birthday: "",
  phone: "",
  email: "",
  email_confirm: "",
  content: "",
  errs: {
    name: "",
    name_kata: "",
    birthday: "",
    phone: "",
    email: "",
    email_confirm: "",
  }
}

const InquiryData = (state = data, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_INQUIRY':
      return {
        ...state,
        ...action.data
      };
    case 'RESET_DATA_INQUIRY':
      return data
    case 'UPDATE_ERRS_INQUIRY':
      return {
        ...state,
        errs: {
          ...state.errs,
          ...action.data.errs
        }
      };
    default:
      return state;
  }
};

export default InquiryData;
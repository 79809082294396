import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Checkbox from 'views/components/Checkbox';
import { updateDesired } from 'actions';

class Desired extends React.Component {

  static propTypes = {
    search_types: PropTypes.object,
  }

  static defaultProps = {
    search_types: {},
  }

  state = {
    all_areas: false,
    type: "",
  }

  handlepassType = (type) => {
    this.setState({
      type: type
    })
  }

  handlePassData = (data, type) => {
    const { areas } = data;
    const { search_types, desireds } = this.props
    if (type === "areas" && areas.indexOf("") > -1) {
      this.setState({
        all_areas: true
      })
      const search_areas_length = search_types["areas"].options.length
      if (desireds["areas"].length === search_areas_length && areas.length !== search_areas_length){
        this.setState({
          all_areas: false
        })
      }
    }
    this.props.updateDesired(data)
  }

  render() {
    const { search_types, desireds } = this.props;
    const { ...new_search_types } = search_types;
    if (this.state.all_areas) {
      desireds["areas"] = new_search_types["areas"].options.map(function(data, i) {
        return data.id;
      });
    } else {
      desireds["areas"] = desireds["areas"].filter(id => id !== "")
    }
    if (new_search_types["areas"] && desireds["areas"].length === new_search_types["areas"].options.length - 1) {
      desireds["areas"].unshift("");
    }
    return (
      <Checkbox
        search_types={new_search_types}
        desireds={desireds}
        passDataToParent={this.handlePassData}
        border_panel="solid 1px #d9d9d9"
      />
    );
  }
}

const mapStoreToProps = store => {
  const { search_types } = store;
  const { areas } = store.search_types;
  if (areas && areas["options"].length === 47) {
    areas["options"].unshift({id: "", name: "全国"})
  }
  return {
    search_types: search_types,
  };
};

export default connect(mapStoreToProps, {updateDesired})(Desired);
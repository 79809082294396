import styled from 'styled-components'
import { Link } from "react-router-dom";
import { Drawer} from 'antd';

export const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  padding: 12px 5px;
  border-bottom: solid 1px #e8e8e8;
  margin-bottom: 10px;
  color: #333;
`

export const DrawerStyle = styled(Drawer)`
 
  .ant-drawer-title{
    text-align: center;
    color: #e95513;
    font-size: 22px;
    text-transform: uppercase;
  }
  .ant-drawer-close{
    left: 0;
    font-size: 26px;
    color: #333;
  }
`

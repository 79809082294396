import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Heading from 'views/components/Heading';
import Checkbox from 'views/components/Checkbox';
import {updateConditionSearch} from 'actions';

import { Container, PrimaryButton } from 'views/commons/styles';
import { Wrapper } from './styled';

class FranchisesSettingSearch extends React.Component {

  static propTypes = {
    FranchisesSearchType: PropTypes.object,
  }

  static defaultProps = {
    FranchisesSearchType: {},
  }

  search_conditions = {};

  handlePassData = (data) => {
    this.search_conditions = data;
  }

  handleSearch = () => {
    this.props.updateConditionSearch(this.search_conditions);
  }

  render() {
    let {
      search_types,
      background,
      color_title,
      border_title,
      padding_title,
      margin_title,
      color_label,
      border_checkbox
    } = this.props;
    
    const { ...new_search_types } = search_types;
    return (
      <Wrapper background={background}>
        <Container >
          {this.props.heading && <Heading>{this.props.heading}</Heading>}
          <Checkbox
            search_types={new_search_types}
            color_title={color_title}
            border_title={border_title}
            padding_title={padding_title}
            margin_title={margin_title}
            color_label={color_label}
            border_checkbox={border_checkbox}
            passDataToParent={this.handlePassData}
          />
          <PrimaryButton 
            display="block" 
            margin="40px auto" 
            onClick={this.handleSearch}
          >
            <Link to="/franchises/search">検索</Link>
          </PrimaryButton>
        </Container>
      </Wrapper>
    )
  }
}

const mapStoreToProps = store => {
  return {
    search_types: store.search_types,
  };
};
export default connect(mapStoreToProps, {updateConditionSearch})(FranchisesSettingSearch);
import React from 'react';
import { Link } from "react-router-dom";
import { Row, Icon } from 'antd';

import { Container, PrimaryButton } from 'views/commons/styles';
import { StyledFixedFooter, LeftCol, Tel, TimeWorking, RightCol } from './styled';

class FixedFooter extends React.Component {
  render() {
    return (
        <StyledFixedFooter>
          <Container type="flex" align="middle">
            <LeftCol xs={12} md={15}>
              <Row type="flex" align="middle">
                <Tel xs={24} md={14} lg={12}>
                  <img src="/images/tell2-icon.png" alt="tell-icon" />
                  <b><a href="tel:0364573186">03-6457-3186</a></b>
                </Tel>
                <TimeWorking xs={24} md={10} lg={12}>
                  <div>
                    <div>受付時間</div>
                    <div>10:00~17:00(平日のみ）</div>
                  </div>
                  <div>メールでのお問合せは年中無休です</div>
                </TimeWorking>
              </Row>
            </LeftCol>
            <RightCol xs={12} md={9}>
              <PrimaryButton background="#fff" color="#e95513" width="100%">
                <Link to="/free_request">
                  <Icon type="caret-right" />
                  <b>無料資料請求</b>
                </Link>
              </PrimaryButton>
            </RightCol>
          </Container>
        </StyledFixedFooter>

    )
  }
}

export default FixedFooter;

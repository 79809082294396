import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Icon } from 'antd';

export const Wrapper = styled.div`
  max-width: 767px;
  width: 100%;
  margin: auto;
  line-height: 1.4;
  text-align: center;
  padding: 15px 15px 40px;
`
export const Error = styled.div`
  position: relative;
  height: 220px;
  h1{
    font-family: 'Kanit', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 186px;
    font-weight: 200;
    margin: 0px;
    background: linear-gradient(130deg, #ffa34f, #ff6f68);
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
  }
  @media (max-width: 463px) {
    height: 168px;
    h1{
      font-size: 112px;
    }
  }
`
export const Title = styled.h2`
  font-family: 'Kanit', sans-serif;
  font-size: 33px;
  font-weight: 200;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 25px;
  letter-spacing: 3px;
  @media (max-width: 463px) {
    font-size: 22px;
  }
`
export const Direction = styled.p`
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  font-weight: 200;
  margin-top: 0px;
  margin-bottom: 25px;
`
export const ReturnTop = styled(Link)`
  font-family: 'Kanit', sans-serif;
  color: #ff6f68;
  font-weight: 200;
  text-decoration: none;
  border-bottom: 1px dashed #ff6f68;
  border-radius: 2px;
  margin-left: 10px;
`
export const Social = styled.a`
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 40px;
  font-size: 14px;
  color: #ff6f68;
  border: 1px solid #efefef;
  border-radius: 50%;
  margin: 3px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  &:hover{
    color: #fff;
    background-color: #ff6f68;
    border-color: #ff6f68;
  }
`

export const StyledIcon = styled(Icon)`
  font-size: 19px;
`
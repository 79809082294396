import styled from 'styled-components'
import { Row } from 'antd';

export const NavbarWrapper = styled(Row)`
  a{
    padding: 0 20px;
    color: #808080;
    border-right: solid 1px #ccc;
    &:last-child{
      border-right: 0;
      padding-right: 0;
    }
  }
`

export default NavbarWrapper;

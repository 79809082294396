import React from "react";
import PropTypes from 'prop-types';
import { Card, Tag } from "antd";
import { Link } from "react-router-dom";
import { PrimaryButton, Tags } from 'views/commons/styles';
import { CardImgGrid } from "../styled";

class Franchise extends React.Component {

  static propTypes = {
    franchise: PropTypes.object,
    id: PropTypes.number,
    image: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    tags: PropTypes.array,
    update: PropTypes.string,
    topic: PropTypes.string,
  }

  static defaultProps = {
    tags: []
  }

  render() {
    const { Meta } = Card;
    const { franchise } = this.props;
    const {
      id,
      title,
      description,
      franchise_features,
      franchise_images,
      areas
    } = franchise;
    const features = franchise_features.map(feature => typeof feature === "object" ? feature.name : feature);
    const working_places = areas.map(place => typeof place ==="object" ? place.name : place);

    return (
      <CardImgGrid
        cover={<div className="img-grid"><img alt="example" src={franchise_images[0].image_url || franchise_images[0]} /></div>}
        bodyStyle={{ padding: "0 10px 25px" }}
        actions={[
          <PrimaryButton fontSize="14px" margin="auto" display="block">
            <Link to={`/franchises/id=${id}`} target="_blank">
              詳細を見る
            </Link>
          </PrimaryButton>
        ]}
      >
        <Tags>
          {
            working_places.map((working_place, i) => {
              return <Tag key={i}>{working_place}</Tag>
            })
          }
        </Tags>
        <Meta title={title} description={`${description.slice(0, 24)}...`} />
        <Tags>
          {
            features.slice(0, 2).map((feature, i) => {
              return <Tag key={i} style={{ height: '100%' }}>{feature}</Tag>
            })
          }
        </Tags>
        
      </CardImgGrid>
    );
  }
}

export default Franchise;





import styled from 'styled-components'
import { Row, Col } from 'antd';

export const StyledFixedFooter = styled(Row)`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index:1;
  background: #e95513;
  color: #fff;
  padding: 8px 0;
`
export const LeftCol = styled(Col)`
  @media (max-width: 518px) {
    width: 100%;
    padding: 0 5%;
    margin-bottom: 7px;
  }
`
export const Tel = styled(Col)`
  display: flex;
  align-items: baseline;
  b a{
    font-size: 27px;
    color: #fff;
    @media (min-width: 768px) {
      font-size: 33px;
    }
  }
  img{
    max-width: 23px;
    margin-right:5px
  }
`
export const TimeWorking = styled(Col)`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    > div > div {
      display: inline-block;
    }
  }
  div{
    font-family: 'Montserrat', sans-serif;
    margin:0 3px;
  }
`
export const RightCol = styled(Col)`
  @media (max-width: 518px) {
      width: 100%;
      text-align: center;
    
  }
`

export default null;
import React from 'react';
import { Container } from 'views/commons/styles';

class Policy extends React.Component {
  render() {
    return (
      <Container padding="30px 0" color="#333">
        株式会社フードバリュークリエイション(以下、「当社」といいます)は、飲食業の中でもラーメン、そば、うどん、パスタなどの『麺』業態に特化したフランチャイズをご紹介するプラットフォーム・サービス(以下、本サービスといいます)のご提供にあたり、お客様の個人情報をお預かりしております。<br />
        当社は、お客様の個人情報を取り扱う企業として個人情報の保護を重要視しており、お客様に更なる信頼性と安心感をご提供できるように努めてまいります。<br/>
        当社は、個人情報に関する法令を遵守し、個人情報の適切な取り扱いを実現致します。<br/>
        <br/>
        1.個人情報の取得について<br />
        お客様から個人情報をご提供頂く場合には、偽りその他不正の手段によらず適正に個人情報を収集致します。<br />
        <br/>
        2.個人情報の利用について<br />
        当社は、お客様より収集した個人情報を、以下に定める利用目的の範囲内で利用致します。<br />
        また、以下の利用目的を超えてお客様の個人情報を利用する必要が生じた場合には、事前にお客様に同意を得た上で利用させて頂きます。<br/>
        ①,本サービスに付随する各種サービス(FC加盟相談、希望条件に合致するFC企業の募集情報の提供、FC企業への紹介)の提供の為<br />
        ②,本サービスの品質向上を目的とするご意見、ご要望等の聴取の為<br />
        ③,情報開示、訂正、削除、利用・提供の拒否時の本人確認の為<br />
        <br/>
        3.個人情報の第三者提供について<br />
        当社は、お客様の個人情報を、お客様の同意を得ることなく、業務委託先以外の第三者に開示致しません。 ただし、以下に定める場合には、利用者の同意を得ずに個人情報を提供する場合があります。<br />
        ①,個人情報保護法、その他の法令により開示を求められた場合<br />
        ②,お客様本人による、第三者への開示または提供の意思表示がある場合<br />
        <br/>
        4.業務委託による個人情報の第三者提供について<br />
        当社は、個人情報取扱業務の全部または一部を、外部に委託する場合があります。
        個人情報取扱業務を外部に委託する場合、当社の責任において、個人情報保護水準を満たしている者を選定し、委託先との間で個人情報の適切な取扱を遵守させるものとします。<br />
        <br/>
        5.個人情報の開示・訂正等について<br />
        当社は、お客様からご自身の個人情報について開示の請求がある場合、速やかに開示を行います。<br />
        ただし、お客様ご自身であることが確認できない場合には開示の請求に応じません。<br />
        個人情報の内容に誤りがあり、お客様から個人情報の訂正・追加・削除の請求がある場合、調査の上、速やかにこれらの請求に対応致します。<br />
        上記の請求・お問合せ等ございましたら、下記までご連絡くださいますようお願い申し上げます。<br />
        【連絡先】株式会社フードバリュークリエイション<br />
        E-mail:info001@food-vc.work<br />
        電話:03-5843-7750<br />
        <br/>
        6.本方針の変更<br />
        本方針の内容は変更されることがあります。<br />
        変更後の本方針については、当社が別途定める場合を除いて、当サイトに掲載した時から効力を生じるものとします。
      </Container>
    )
  }
}

export default Policy;

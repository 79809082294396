import styled from 'styled-components';
import { Container } from 'views/commons/styles';

export const StyledContainer = styled(Container)`
  position: sticky;
  -webkit-overflow-scrolling:touch;
  width: 100%;
  top: 0;
  z-index: 2;
  background: #fff;
  padding: 10px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.37);
`

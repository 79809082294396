import React from "react";
import PropTypes from 'prop-types';
import { Collapse, Icon, Button } from "antd";

import { Container, IconCollap } from 'views/commons/styles';
import { StyledPanel, StyledCheckboxGroup } from './styled';

class CheckboxSP extends React.Component {

  static propTypes = {
    searchTypes: PropTypes.object,
  }

  static defaultProps = {
    searchTypes: {},
  }

  state = {
    setting_data: {
      targets: [],
      areas: [],
      features: []
    },
    activeKey: ["0"]
  }

  handleCheckbox = (value, type) => {
    this.setState({
      setting_data: {
        ...this.state.setting_data,
        [type]: value
      }
    }, () => this.props.passDataToParent(this.state.setting_data), type);
  }

  handleTogglePanel = (keys) => {
    this.setState({
      ...this.state,
      activeKey: keys
    });
  }

  handleClosePanel = (key) => {
    let { activeKey } = this.state;
    let index = activeKey.indexOf(key.toString());
    activeKey.splice(index, 1);
    this.setState({
      ...this.state,
      activeKey
    });
  }

  render() {
    const {
      search_types,
      border_panel,
      background_panel,
      background_expand_icon,
      color_expand_icon,
      desireds
    } = this.props;
    const types = Object.keys(search_types);
    const { activeKey } = this.state;
    return (
      <Container>
        <Collapse
          style={{background: "transparent"}}
          bordered={false}
          defaultActiveKey={activeKey}
          onChange={(keys) => this.handleTogglePanel(keys)}
          expandIcon={({ isActive }) => {
            return(
              <IconCollap 
                background_expand_icon={background_expand_icon}
                color_expand_icon={color_expand_icon}
                type={`${isActive ? "minus" : "plus"}`}
                
              />
            )
          }}
        >
          {
            types.length ? types.map((type, i) => {
              const value = desireds ? desireds[type] : [];
              const options = search_types[type].options.map((option) => {
                return { value: option["id"], label: option["name"] };
              });
              return (
                <StyledPanel
                  header={search_types[type].title}
                  key={i}
                  border_panel={border_panel}
                  background_panel={background_panel}
                >
                  <StyledCheckboxGroup
                    style={{ lineHeight: "2.3em" }}
                    options={options}
                    onChange={value => this.handleCheckbox(value, types[i])}
                    value={value}
                  />
                  <Button style={{ margin: "30px auto", display: "block", width: "240px", height: "45px" }}
                    onClick={() => this.handleClosePanel(i)}
                  >
                    <Icon type="caret-up" /> 閉じる
                  </Button >
                </StyledPanel>
              )
            }) : null
          }
        </Collapse>
      </Container>
    );
  }
}

export default CheckboxSP;
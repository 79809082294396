import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import {updateDataForm} from 'actions';
import { StyledTextarea } from './styled';


class TextArea extends Component {

  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
  }

  passData = (e) => {
    const {reducer} = this.props;
    const name = e.target.name;
    const value = e.target.value;
    this.props.updateDataForm({[name]: value, reducer})
  }


  render() {
    const { name, value, placeholder } = this.props;
    return (
      <StyledTextarea
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={this.passData}
      />
    );
  }
}

export default connect(
  null,
  {updateDataForm}
)(TextArea);

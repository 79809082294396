import styled from 'styled-components';
import { Col, Row } from 'antd';

export const StepWrapper = styled(Row)`
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin: 15px 0 30px;
  }
`

export const Step = styled(Col)`
  -webkit-clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
  background: #cccccc;
  color:#fff;
  display:flex;
  align-items: center;
  min-height: 50px;
  justify-content: center;
  flex-wrap:wrap;
  padding: 5px;
  width: 24%;
  margin-right: 1%;
  &.active{
    background: #e95513;
  }
  @media (max-width: 600px) {
    -webkit-clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    font-size: 13px;
  }
  @media (max-width: 450px) {
    font-size: 11px;
    padding: 16px;
  }
`
export const StepIndex = styled.span`
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom:-3px;
  }
`

export const ErrMsg = styled(Row)`
  color: red;
`;


export default null;

import React from 'react';
import PropTypes from 'prop-types';
import { Row } from "antd";
import CompanyCharacteristic from './CompanyCharacteristic';
import { StyledPost } from '../styled';

class CompanyCharacteristics extends React.Component {
  static propTypes = {
    posts: PropTypes.array,
    category: PropTypes.string,
  }

  render() {
    const { company_characteristics } = this.props;
    return (
      <Row type="flex">
        {
          company_characteristics.map((companyCharacteristic, i) => {
            return (
              <StyledPost key={i} xs={12} lg={6}>
                <CompanyCharacteristic companyCharacteristic={companyCharacteristic} />
              </StyledPost>
            );
          })
        }
      </Row>
    )
  }
}

export default CompanyCharacteristics;

import React from 'react';

import Loading from 'views/components/Loading';
import Heading from 'views/components/Heading';
import CompanyCharacteristics from 'views/components/ListPost/CompanyCharacteristics';
import { Container } from 'views/commons/styles';
import { fetchCompanyCharacteristics } from 'apis';
import { Pagination } from 'antd';


class CompanyCharacteristicListPage extends React.Component {
  state = {
    is_fetching: true,
    total_count: 0,
    current_page: 1,
    company_characteristics: [],
  };

  getCompanyCharacteristics = (params) => {
    fetchCompanyCharacteristics({ page: params }).then(data => {
      this.setState({
        is_fetching: false,
        company_characteristics: data.company_characteristics,
        total_count: data.meta.total_count,
        current_page: params,
      });
    });
  }

  componentDidMount() {
    const curpage = parseInt(this.props.match.params.curpage);
    this.getCompanyCharacteristics(curpage);
  }

  changePage = (new_page) => {
    this.props.history.push(`/company_characteristics/page=${new_page}`);
    this.setState({ is_fetching: true });
    this.getCompanyCharacteristics(new_page);
  }

  render() {
    const  { is_fetching, company_characteristics, current_page, total_count } = this.state;
    return (
      <Container>
        <Heading>コラム・バックナンバー</Heading>
        {
          is_fetching
            ? <Loading amount={12} />
            : <>
              <CompanyCharacteristics company_characteristics={company_characteristics} />
              <Pagination
                total={total_count}
                defaultPageSize={company_characteristics.length}
                showTotal={total => `全 ${total} 件`}
                current={current_page}
                pageSize={12}
                onChange={this.changePage}
                style={{ margin: "20px 0", textAlign: "right" }}
              />
              </>
        }
      </Container>
    )
  }
}

export default CompanyCharacteristicListPage;
import React, { Component } from 'react';
import { Select } from 'antd';
import { connect } from "react-redux";

import { updateDataForm } from 'actions';
import { prefectures } from 'constants/Prefectures';

class Prefecture extends Component {

  state = {
    value: this.props.value
  };

  selectChange = value => {
    const {reducer} = this.props;
    this.setState({ value: value });
    this.props.updateDataForm({ prefecture: value, reducer })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value })
    }
  }

  render() {
    const Option = Select.Option;
    const { value } = this.state;
    return (
      <Select
        style={{ width: 200 }}
        onChange={this.selectChange}
        value={value}
      >
        {
          prefectures.map(prefecture => <Option key={prefecture}>{prefecture}</Option>)
        }
      </Select>
    );
  }
}

export default connect(
  null,
  {updateDataForm}
)(Prefecture);
import styled from 'styled-components'
import { Modal } from 'antd';


export const ModalStyle = styled(Modal)`
  .ant-modal-content{
    background: none;
    box-shadow: none;
    .ant-modal-header{
      background: none;
      border-bottom: none;
      text-align: center;
      .ant-modal-title{
        color: #fff;
        font-size: 20px;
      }
    }
    .ant-modal-close{
      color: #c7c7c7;
      span{
        font-size: 22px;
      }
    }
  }
`

export default ModalStyle;
import React from "react";
import PropTypes from 'prop-types';
import { Wrapper, StyledTitle, StyledCheckBox } from './styled';

class CheckboxPC extends React.Component {
  static propTypes = {
    searchTypes: PropTypes.object,
  }

  static defaultProps = {
    searchTypes: {},
  }

  state = {
    targets: this.props && this.props.desireds ? this.props.desireds.targets : [],
    areas: this.props && this.props.desireds ? this.props.desireds.areas : [],
    features: this.props && this.props.desireds ? this.props.desireds.features : []
  }

  handleCheckbox = (value, type) => {
    this.setState({
      targets: this.props.desireds.targets,
      areas: this.props.desireds.areas,
      features: this.props.desireds.features
    })
    this.setState({
      [type]: value
    }, () => this.props.passDataToParent(this.state, type));
  }

  render() {
    const {
      search_types,
      desireds,
      color_title,
      border_title,
      padding_title,
      margin_title,
      color_label,
      border_checkbox
    } = this.props;
    const types = Object.keys(search_types);
    return (
      <>
        {
          types.length ? types.map((type, i) => {
            const value = desireds ? desireds[type] : [];
            const options = search_types[type].options.map((option) => {
              return { value: option["id"], label: option["name"] };
            })
            return (
              <Wrapper key={i}>
                <StyledTitle
                  search_types={search_types}
                  color_title={color_title}
                  border_title={border_title}
                  padding_title={padding_title}
                  margin_title={margin_title}
                >
                  {search_types[type].title}
                </StyledTitle>
                <StyledCheckBox
                  options={options}
                  color_label={color_label}
                  border_checkbox={border_checkbox}
                  onChange={value => this.handleCheckbox(value, types[i])}
                  value={value}
                />
              </Wrapper>
            )
          }) : null
        }
      </>
    );
  }
}

export default CheckboxPC;


import { combineReducers, createStore } from "redux";
import FranchisesSearchType from "./FranchisesSearchType";
import FranchisesSearchCondition from "./FranchisesSearchCondition";
import PostDetail from "./PostDetail";
import PostsRelated from "./PostsRelated";
import FormRequestData from "./FormRequest";
import CompanyRequestData from "./CompanyRequest";
import InquiryData from "./Inquiry";

// Combine Reducer
let ReducerCombine = combineReducers({
  search_types: FranchisesSearchType,
  search_conditions: FranchisesSearchCondition,
  PostDetail,
  PostsRelated,
  FormRequestData,
  InquiryData,
  CompanyRequestData
});

//Tạo Store
let MyStore = createStore(ReducerCombine);

export default MyStore;
import React from 'react';
import { Col } from 'antd';
import { Link } from "react-router-dom";

import Navbar from './Navbar';
import SearchModal from 'views/components/SearchModal';
import {StyledContainer} from  './styled';

class HeaderSP extends React.Component {
  render() {
    return (
      <>
        <StyledContainer type="flex" align="middle" justify="center" >
          <Col span={3}>
            <Navbar />
          </Col>
          <Col span={18} style={{padding:"0 20px", textAlign:"center"}}>
            <Link to="/"><img src="/images/logo.png" alt="logo" /></Link>
          </Col>
          <Col span={3} style={{ textAlign: "right" }}>
            <SearchModal>
              <img src="/images/search2-icon.png" alt="search-icon" />
            </SearchModal>
          </Col>
        </StyledContainer>
      </>
    )
  }
}


export default HeaderSP;

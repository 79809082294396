import React from 'react';
import MediaQuery from 'react-responsive';

import CheckboxPC from './PC';
import CheckboxSP from './SP';

class Checkbox extends React.Component {
  render() {
    const {
      search_types,
      desireds,
      color_title,
      border_title,
      padding_title,
      margin_title,
      color_label,
      border_checkbox,
      border_panel
    } = this.props;
    return (
      <>
        <MediaQuery minWidth={965}>
          <CheckboxPC 
            search_types={search_types}
            desireds={desireds}
            color_title={color_title}
            border_title={border_title}
            padding_title={padding_title}
            margin_title={margin_title}
            color_label={color_label}
            border_checkbox={border_checkbox}
            passDataToParent={(data, type) => this.props.passDataToParent(data, type)}
          />
        </MediaQuery>
        <MediaQuery maxWidth={964}>
          <CheckboxSP 
            search_types={search_types}
            border_panel={border_panel}
            desireds={desireds}
            passDataToParent={(data, type) => this.props.passDataToParent(data, type)}
          />
        </MediaQuery>
      </>
    )
  }
}

export default Checkbox;


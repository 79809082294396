import React from 'react';
import { Pagination } from 'antd';
import { connect } from "react-redux";

import { Container } from 'views/commons/styles';
import Heading from 'views/components/Heading';
import Loading from 'views/components/Loading';
import Franchises from 'views/components/ListPost/Franchises';
import { transformSearchCondition } from 'helpers/TransformData';
import { fetchFranchises } from 'apis';

class ResultSearchPage extends React.Component {

  state = {
    is_fetching: true,
    total_count: 0,
    current_page: 0,
    franchises: [],
  };

  getFranchises = (params) => {
    fetchFranchises(params).then((data) => {
      this.setState({
        is_fetching: false,
        franchises: data.franchises,
        total_count: data.meta.total_count,
        current_page: data.meta.current_page
      })
    })
  }

  componentDidMount() {
    const { search_conditions } = this.props;
    this.getFranchises(transformSearchCondition(search_conditions))
  }

  componentDidUpdate(prevProps) {
    const { search_conditions } = this.props;
    if (search_conditions !== prevProps.search_conditions) {
      this.getFranchises(transformSearchCondition(search_conditions))
    }
  }

  changePage = (new_page) => {
    this.props.history.push(`/franchises/search/page=${new_page}`);
    this.setState({ is_fetching: true });
    this.getFranchises({ page: new_page });
  }

  render() {
    const { is_fetching, franchises, current_page, total_count } = this.state;
    return (
      <Container>
        <Heading>フランチャイズ</Heading>
        {
          is_fetching
            ? <Loading amount={8} />
            : <>
              <Franchises franchises={franchises} />
              <Pagination
                total={total_count}
                defaultPageSize={franchises.length}
                showTotal={total => `全 ${total} 件`}
                pageSize={12}
                current={current_page}
                onChange={this.changePage}
                style={{ margin: "20px 0", textAlign: "right" }}
              />
            </>
        }
      </Container>
    )
  }
}

const mapStoreToProps = store => {
  return {
    search_conditions: store.search_conditions,
  };
};
export default connect(mapStoreToProps)(ResultSearchPage);


const data = {
  company_name: "",
  sender_name: "",
  address: "",
  phone: "",
  email: "",
  website: "",
  content: "",
  errs: {
    company_name: "",
    representative: "",
    address: "",
    phone: "",
    email: "",
    website: "",
    content_inquiry: ""
  }
}

const CompanyRequestData = (state = data, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_COMPANY_REQUEST':
      return { ...state, ...action.data };
    case 'RESET_DATA_COMPANY_REQUEST':
      return data
    case 'UPDATE_ERRS_COMPANY_REQUEST':
      return {
        ...state,
        errs: {
          ...state.errs,
          ...action.data.errs
        }
      };
    default:
      return state;
  }
};

export default CompanyRequestData;
import React from 'react';
import { StyledCarousel, StyledPrev, StyledNext } from "./styled";
import MediaQuery from 'react-responsive';

class Slider extends React.Component {

  constructor(props) {
    super(props);
    this.carousel = React.createRef();
  }
  nextSlide = () => {
    this.carousel.next();
  }
  prevSlide = () => {
    this.carousel.prev();
  }

  render(){
    const { images } = this.props;
    return (
      <>
        {
          images.length > 1 && <MediaQuery maxWidth={768}>
            <StyledPrev onClick={this.prevSlide} type="left" />
          </MediaQuery>
        }
        <StyledCarousel
          ref={node => (this.carousel = node)}
        >
          {
            images.map((image, i) => {
              return (
                <img key={i} src={image.image_url} alt="slider" />
              )
            })
          }
        </StyledCarousel>
        {
          images.length > 1 && <MediaQuery maxWidth={768}>
            <StyledNext onClick={this.nextSlide} type="right" />
          </MediaQuery>
        }
      </>
    )
  }
}


export default Slider;


export const fields = [
  {
    tag: "input",
    type: "text",
    name: "company_name",
    field_name: "企業・店舗名",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "sender_name",
    field_name: "ご担当者様",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "address",
    field_name: "住所",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "phone",
    field_name: "電話番号",
    placeholder: "000-000-000",
    required: true
  },
  {
    tag: "input",
    type: "email",
    name: "email",
    field_name: "メールアドレス",
    placeholder: "example@gmail.com",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "website",
    field_name: "ウェブサイト",
    required: false
  },
  {
    tag: "textarea",
    name: "content",
    field_name: "お問い合わせ内容",
    required: true
  },
]

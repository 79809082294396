import styled from 'styled-components'
import { Row, Col} from 'antd';

export const StyledGrid = styled(Row)`
  margin-bottom: 3px;
  border: ${props => props.border_field};
`
export const FieldName = styled(Col)`
  background: #cccccc;
  color: #000;
  align-items: center;
  font-weight: bold;
  display : ${props => props.display};
  padding: 7px;
  @media (min-width: 769px) {
    padding: ${props => props.style_padding};
  }
  @media (max-width: 768px) {
    justify-content: ${props => props.justify};
  }
`
export const Value = styled(Col)`
  padding: 7px;
  line-height: 2.2em;
  background: ${props => props.background || '#f2f2f2'};
`

export default null;
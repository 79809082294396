import queryString from 'query-string';
import { URL } from 'configs';

// GET REQUEST
export const getRequest = ({ endPoint, params = {} }) => {
  const default_params = {
    page: 1,
    order_field: "id",
    order_by: "desc"
  }
  const update_params = {
    ...default_params,
    ...params
  }
  const  API_URL = `${URL}/${endPoint}?${queryString.stringify(update_params)}`;
  return fetch(API_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response =>  response.json())
    .catch(error => console.error('Error:', error));
}

// POST REQUEST
export const postRequest = ({ endPoint, data = {} }) => {
  const  API_URL = `${URL}/${endPoint}`;
  return fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .catch(error => console.error('Error:', error));
}

// PUT REQUEST
export const putRequest = ({ endPoint, data = {} }) => {

  const  API_URL = `${URL}/${endPoint}`;

  return fetch(API_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .catch(error => console.error('Error:', error));
}


// DELETE REQUEST
export const deleteRequest = ({ endPoint }) => {

  return fetch(endPoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then(response => response.json())
    .catch(error => console.error('Error:', error));
}




import styled from 'styled-components';
import { Col} from 'antd';

export const LinkTitle = styled.div`
  color: #000000;
  font-weight: bold;
  font-size: 11px;
`
export const Anchor = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align:center;
  border: solid 1px #e95513;
  border-radius: 5px;
  position: relative;
  padding:  3px 8px;
  background: ${props => props.backgroundanchor || "#fff"}; 
`;

export default null;
import React from 'react';
import MediaQuery from 'react-responsive';
import { connect } from "react-redux";

import SearchModalPC from './PC';
import SearchModalSP from './SP';

class SearchModal extends React.Component {
  render() {
    const {search_types} = this.props;
    const { ...new_search_types } = search_types;
    return (
      <>
        <MediaQuery minWidth={965}>
          <SearchModalPC search_types={new_search_types} />
        </MediaQuery>
        <MediaQuery maxWidth={964}>
          <SearchModalSP 
            onClose={this.props.onClose}
            search_types={new_search_types}>
            {this.props.children}
          </SearchModalSP>
        </MediaQuery>
      </>
    )
  }
}

const mapStoreToProps = store => {
  return {
    search_types: store.search_types,
  };
};

export default connect(mapStoreToProps)(SearchModal);


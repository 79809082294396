import React from 'react';
import { Link } from "react-router-dom";

import Field from 'views/components/Form';
import Instruction from 'views/components/Form/Instruction';
import { fields as FreeRequestFields } from 'constants/FreeRequestFields';
import { fields as FranchisesEntryFields } from 'constants/FranchisesEntryFields';
import { Container } from 'views/commons/styles';

class FirstStep extends React.Component {
  render() {
    const { data, franchise_id } = this.props;
    const fields = !franchise_id ? FreeRequestFields : FranchisesEntryFields;
    return (
      <>
        <Instruction>
          必要事項を記入し、下記の利用規約をご確認後、「規約に同意して確認画面へ」ボタンを押してください。
        </Instruction>
          <Container padding="20px 0 0">
            {
              fields.map((field, i) => {
                return (
                  <Field
                    key={i}
                    reducer="FORM_REQUEST"
                    align={`${(field.tag === "textarea" || field.name === "desired") ? "top" : "middle"}`}
                    field={field}
                    value={data[field.name]}
                    err={data.errs[field.name]}
                    autoFillDataToParent={this.autoFillData}
                    ref={field.name}
                  />
                )
              })
            }
            <div style={{ textAlign: "center", margin: "20px auto" }}>
              <Link to="/policy">個人情報保護方針</Link>
              <b>をご確認の上、「利用規約に同意して確認画面へ」ボタンを押して次の画面へ進んでください。</b>
            </div>
          </Container>
      </>
    )
  }
}

export default FirstStep
import React from 'react';
import Heading from 'views/components/Heading';
import { Link } from "react-router-dom";

import { Container, PrimaryButton, DescriptionPage } from 'views/commons/styles';

class About extends React.Component {
  render() {
    return (
      <>
        <Container>
          <DescriptionPage>
            フードフランチャイズナビ『麺』は、飲食業の中でもラーメン、そば、うどん、パスタなどの『麺』業態に特化したフランチャイズをご紹介するプラットフォームです。
            <br />売上の季節変動が少なく、初期投資も比較的低く、ホールオペレーションも簡素化され利益率が高い麺業態に絞り麺業界に進出する方々に全国から優良ＦＣ本部をご紹介しています。
            <br />また、約5坪から20坪で開業出来るため、不動産関連投資も抑えられ毎月の固定費となる家賃も安く抑えられるのも特徴です。
            <br />弊社では、首都圏を中心に、全国主要都市の飲食店向け賃貸物件情報のご提供、厨房機器（中古も含む）等飲食店の出店に必要不可欠なサービス提供も行っていますので、できるだけ安価に出店できるようお手伝いいたします。
          </DescriptionPage>
          <PrimaryButton display="block" margin="40px auto">
            <Link to="/free_request">無料資料請求</Link>
          </PrimaryButton>
        </Container>

        <div style={{ background: "#f2f2f2", padding: "30px 10px" }}>
          <Container>
            <DescriptionPage>
              私たちの強みは・・・
              <br />飲食業界に長く、直積的、間接的に携わっていることです。約30年にわたり飲食業界に携わってきたメンバーを中心に、弊社グループ会社は、現在約7000店舗の飲食店ユーザーを抱えるＩＴ企業。そして、飲食店も実際に経営し良いところ大変なところを身をもって体験しているからこそ、できる事があります。そして、なにより飲食店が好き！これが1番の強みかもしれません。
            </DescriptionPage>
            <Heading>業界に強い理由</Heading>
            <b style={{ fontSize: "17px", marginBottom: "15px", display: "block" }}>飲食企業を知り尽くした経験とノウハウ</b>
            <div>
              飲食業界に約30年携わってきた経験から、フードフランチャイズナビ『麺』は、居酒屋や焼肉、やきとりではなく『麺』業態に絞ったプラットフォームを開設しました。その理由は、飲食業界を取り巻く環境を考慮し、低投資、低コスト、早期の投資回収が可能、景気左右が少ない、人員確保が他業態と比べ容易でることなどから『麺』業態に絞りましたが、麺業態も競争が激しく繁盛するのは容易なことではありません。
              <br/>弊社のノウハウをもとに全国から優良ＦＣを抽出しご提供することで貴社の状況、ご要望に合うＦＣ、ボランタリー業態との出会いに全力を尽くしています。
            </div>
            <br /><br />
            <Heading>お気軽にご相談ください。</Heading>
            <div>
              資料請求だけでなく、ＦＣ本部の話を聞く前に複数の業態の話が聞いてみたいなど、なんでもお気軽にお問合せください。
              <br />弊社が中立な立場で貴社のご不明点などにお答えいたします。
            </div>
            <PrimaryButton display="block" margin="20px auto 0">
              <Link to="/inquiry">お問い合わせ</Link>
            </PrimaryButton>
          </Container>
        </div>
      </>
    )
  }
}

export default About;

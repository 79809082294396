import React from 'react';
import FranchisesSettingSearch from 'views/components/FranchisesSettingSearch';

class FranchisesSearchPage extends React.Component {
  
  render() {
    return (
      <FranchisesSettingSearch 
        color_title="#e95513"
        border_title="solid 1px"
        padding_title="0 0 10px 0"
        margin_title="0 0 10px 0"
        color_label="#000000"
        border_checkbox="solid 1px #000000"
      />
    )
  }
}

export default FranchisesSearchPage;

import styled from 'styled-components';

export const StyledIdentification = styled.div`
  background : #333333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 25px;
  font-weight: bold;
  @media (max-width: 518px) {
    font-size:20px;
    height: fit-content;
    min-height: 45px;
    padding: 10px;
  }
`
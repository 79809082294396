import React from 'react';
import { Icon } from 'antd';

import PropTypes from 'prop-types';
import Heading from 'views/components/Heading';
import Loading from 'views/components/Loading';
import CompanyCharacteristics from 'views/components/ListPost/CompanyCharacteristics';
import { Container, SeeMore } from 'views/commons/styles';
import { fetchBackNumberCompanyCharacteristics } from 'apis';


class BackNumberCompanyCharacteristicList extends React.Component {
  static propTypes = {
    companyCharacteristicId: PropTypes.number
  }

  state = {
    is_fetching: true,
    company_characteristics: []
  };

  componentDidMount() {
    const {companyCharacteristicId} = this.props;
    fetchBackNumberCompanyCharacteristics(companyCharacteristicId, {per_page: 4}).then(data => {
      this.setState({
        is_fetching: false,
        company_characteristics: data.company_characteristics,
      })
    })
  }

  render() {
    const { is_fetching, company_characteristics } = this.state;
    return (
      <Container>
        <Heading>バックナンバー</Heading>
        {
          is_fetching
          ? <Loading amount={4} />
          : <CompanyCharacteristics company_characteristics={company_characteristics} />
        }
        <SeeMore to="/company_characteristics">
          <Icon type="caret-right" />もっと見る
        </SeeMore>
      </Container>
    )
  }
}

export default BackNumberCompanyCharacteristicList;
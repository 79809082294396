import React, { Component } from 'react';
import { Require } from './styled';

import {NameWrapper, StyledFieldName, Note} from './styled';

class FieldName extends Component {
  
  render() {
    const {require, note} = this.props;
    return (
      <NameWrapper>
          <StyledFieldName xs={6} sm={12} md={16}>
            {this.props.children}
          </StyledFieldName>

          { require && <Require>必須</Require>}
         
          {
            note ? <Note xs={12} sm={24}>{this.props.note}</Note> : null
          }
      </NameWrapper>
    );
  }
}

export default FieldName;

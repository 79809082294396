import styled, { } from 'styled-components';
import { Link } from "react-router-dom";
import { Col, Card } from "antd";

export const StyledPost = styled(Col)`
  padding: 0 8px;
  margin-bottom: 20px;
`

export const ViewDetail = styled(Link)`
  border: solid 1px #ccc;
  padding: 5px 10px;
  margin: auto;
  margin-top: 20px;
  width: 90%;
  display: block;
  text-align: center;
`
export const CardImgGrid = styled(Card)`
  height: 100%;
  padding-bottom: 2rem;
  .ant-card-cover {
    display: grid;
    grid-template-columns: 1fr;
    .img-grid {
      height: 100%;
      &:before {
        content: "";
        float: left;
        padding-top: 65%;
      }
      img {
        height: 100%;
        max-height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .ant-card-actions {
    position: absolute;
    width: 100%;
    bottom: 0;
    border: 0;
    padding: 0px 10px 10px;
    background: none;
    li {
      margin: 0;
      width: 100%;
      display: grid;
    }
  }
`

export default null;
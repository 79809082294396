import React from 'react';
import MediaQuery from 'react-responsive';
import { Collapse, Icon } from "antd";
import {qas} from 'constants/Qa';

import { PrimaryButton, Container, IconCollap } from 'views/commons/styles';
import { StyledPanel, Item, Ques, QuesIcon, QuesText, Ans, AnsIcon, AnsText } from './styled';

const Question = (ques) => {
  return (
    <Ques type="flex">
      <QuesIcon xs={2}>Q</QuesIcon>
      <QuesText xs={20}>{ques}</QuesText>
    </Ques>
  )
}

const Answer = (ans) => {
  return (
    <Ans type="flex">
      <AnsIcon xs={2}>A</AnsIcon>
      <AnsText xs={20}>{ans}</AnsText>
    </Ans>
  )
}

class QaPage extends React.Component {

  state = {
    activeKey: ["0"]
  }

  handleTogglePanel = (keys) => {
    this.setState({
      activeKey: keys
    });
  }

  handleClosePanel = (key) => {
    let { activeKey } = this.state;
    let index = activeKey.indexOf(key.toString());
    activeKey.splice(index, 1);
    this.setState({
      activeKey
    });
  }

  render() {
    const { activeKey } = this.state;
    return (
      <Container>
        <MediaQuery minWidth={465}>
          {
            qas.map((qa, i) => {
              return (
                <Item key={i}>
                  {Question(qa.ques)}
                  {Answer(qa.ans)}
                </Item>
              )
            })
          }
        </MediaQuery>
        <MediaQuery maxWidth={466}>
          <Collapse
            defaultActiveKey={activeKey}
            bordered={false}
            expandIcon={({ isActive }) => <IconCollap margin="7px 0 0" type={`${isActive ? "minus" : "plus"}`} />}
            onChange={(keys) => this.handleTogglePanel(keys)}
          >
            {
              qas.map((qa, i) => {
                return (
                  <StyledPanel
                    header={Question(qa.ques)}
                    key={i}
                    style={{borderBottom: "0px"}}
                  >
                    {Answer(qa.ans)}
                    <PrimaryButton 
                      margin="30px auto 15px"
                      width="230px"
                      height="45px"
                      background="#fff"
                      color="#666666"
                      display="block"
                      fontSize="14px"
                      onClick={() => this.handleClosePanel(i)}
                    >
                      <Icon type="caret-up" /> 閉じる
                    </PrimaryButton >
                  </StyledPanel>
                )
              })
            }
          </Collapse>
        </MediaQuery>
      </Container >
    )
  }
}

export default QaPage;

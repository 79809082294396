import styled, { } from 'styled-components';
import { Col } from "antd";

export const BannerItem = styled(Col)`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 8px;
  margin-bottom: 20px;
  a {
    width: 100%;
    height: 100%;
    display: table;
    &:before {
      content: '';
      float: left;
      padding-top: 65%;
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
`

export default null;
import styled from 'styled-components'
import { Row, Button, Icon, Tag } from 'antd';
import { Link } from "react-router-dom";

export const colors = {
  PRIMARY: '#e95513',
  GRAY: '#e6e6e6'
};

export const Container = styled(Row)`
  width: 95%;
  max-width:  ${props => props.maxwidth || '965px'};
  margin : ${props => props.margin || "auto"};
  padding : ${props => props.padding };
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
`

export const PrimaryButton = styled(Button)`
  background: ${props => props.background || '#e95513'};
  font-size: ${props => props.font_size};
  color: ${props => props.color || '#fff'};
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '45px'};
  display : ${props => props.display};
  margin : ${props => props.margin};
  border: ${props => props.border_button || 'solid 1px #f2f2f2'};
  max-width: 400px;
`
export const GroupButton = styled(Row)`
  display: flex;
  max-width:  ${props => props.max_width || '500px'};
  margin: 15px auto;
  justify-content:center;
  flex-wrap: wrap;
  button{
    margin: 5px
  }
`;

export const DescriptionPage = styled.h1`
  color: #e95513;
  font-weight: bold;
  text-align: center;
  line-height: 1.8em;
  width: 90%;
  margin: auto;
  font-size: 25px;
  margin: 10px auto;
  margin-bottom: 30px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
`

export const Tags = styled.div`
  line-height: 2em;
  margin: 10px auto;
  min-height: ${props => props.min_height};
`

export const StyledTag = styled(Tag)`
  background:  ${props => props.background_tag || '#f2f2f2'};
  color: ${props => props.color_tag || '#808080'};
  border: ${props => props.border_tag || 'none'};
  border-radius: 3px;
  display: inline-block;
  padding: 5px 10px;
  box-sizing: content-box;
  margin-bottom: 5px;
`

export const IconCollap = styled(Icon)`
  margin: ${props => props.margin};
  svg{
    font-size: 24px;
    background: ${props => props.background_expand_icon || '#fff'};
    border-radius: 100%;
    padding: 5px;
    color: ${props => props.color_expand_icon || '#777'};
  } 
`
export const SeeMore = styled(Link)`
  color: #e95513;
  float: right;
  margin: 10px 0;
  font-weight: bold;
`
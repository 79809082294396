export const qas = [
  {
    ques: "フードフランチャイズナビ『麺』を利用するメリットは何ですか？",
    ans: "飲食業界の中でも日本で初めて、ラーメン、そば、うどん、パスタなどの麺業態に特化した飲食店フランチャイズＦＣ、ボランタリー募集のプラットフォームですので、麺業界にご興味のある方、企業様にとって効率よく比較検討ができます。また、弊社に直接お問い合わせいただければ、貴殿、貴社の状況やご要望に中立な立場でご回答、ご提案が可能です。"
  },
  {
    ques: "資料請求や相談で費用は掛かりますか？",
    ans: "ご安心ください。一切費用は掛かりません。掲載フランチャイズ募集本部様に掲載費用という形でご負担いただいています。\n加盟希望様は、資料請求⇒本部との面談説明（事前に弊社へ相談可能）その後は、ＦＣ本部と加盟条件に基づきオープン！となります。\nまた、開店にかかわる資金相談（融資、リース、敷金のファイナンス等）も弊社にて賜りますのでお気軽にご相談ください。"
  },
  {
    ques: "まだ、ＦＣ加盟を決めているわけではないが登録は可能ですか？",
    ans: "大丈夫です。検討段階から登録し、資料請求などを行い情報収集を行ってください。順次、全国から優良フランチャイズＦＣ、ボランタリー本部の情報が増えていきますのでお気軽に問い合わせをしてください。また、新着フランチャイズＦＣ、ボランタリー本部の情報は、登録いただければメールにてお知らせいたします。"
  },
  {
    ques: "開店費用の調達の支援もありますか？",
    ans: "はい、貴殿、貴社の状況やご要望に応じてご支援いたしています。例えば、厨房機器のリース活用や出店予定不動産物件の敷金の流動化や融資あっけんまでお手伝いいたしています。"
  },
  {
    ques: "開店時に必要になる厨房機器、内装などの支援はありますか？",
    ans: "はい、開店に必要な厨房機器や内装、券売機かＰＯＳレジなど新品、中古どちらもご紹介可能です。安価で問題のない開店をお手伝いいたします。"
  },
  {
    ques: "出店希望エリアの賃貸物件の斡旋も可能ですか？",
    ans: "はい、全国に飲食店専門の独自のネットワークを有しています。賃貸物件は、タイミングも必要ですが、ご希望に添えるよう日々ネットワークの強化を行っていますので、お気軽にご活用ください。別件契約完了までは、完全無料です。"
  },
];
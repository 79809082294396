import styled from 'styled-components';
import { Col } from 'antd';

export const Anchor = styled(Col)`
  border: solid 1px #e95513;
  border-radius: 5px;
  padding: ${props => props.padding_anchor || "7px"}; 
  position: relative;
  background: ${props => props.backgroundanchor || "#fff"}; 
  &:after{
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border: solid 6px transparent;
    border-bottom: solid 6px #e95513;
    border-right: solid 6px #e95513;
    right: 5px;
    bottom: 5px;
  }
  a{
    display: flex;
    flex-direction: ${props => props.direction || "column"};
    justify-content: ${props => props.justify || "center"};
    align-items: center;
    text-align: center;
    height: 100%; 
  }
`;

export const LinkTitle = styled.div`
  color: #000000;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 11px
  }
`
export const Icon = styled.img`
  padding: 0 10px;
`
export const Actions = styled(Col)`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  @media (max-width: 767px) {
    margin-top: 15px;
  }
`

export default null;
import styled, { keyframes } from 'styled-components'

const navbar_slide_down = keyframes`
  0% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
`

export const StyledHeader = styled.header`
  background: #fff;
  z-index: 5;
  &.styleBoxShadow{
    position:fixed;
    width: 100%;
    animation: ${navbar_slide_down} 0.3s ease-out;
    top: 0;
    -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  }
`
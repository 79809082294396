import React from 'react';
import PropTypes from 'prop-types';

import CharacteristicQuestions from 'views/components/ListPost/CharacteristicQuestions';
import BackNumberCompanyCharacteristicList from './BackNumberCompanyCharacteristicList';
import RelatedFranchiseList from './RelatedFranchiseList';
import Profile from './Profile';
import { Container } from 'views/commons/styles';
import { Title } from './styled';

class CompanyCharacteristicDetail extends React.Component {
  static propTypes = {
    company_characteristic: PropTypes.object
  }

  render() {
    const {company_characteristic} = this.props;
    const {
      id,
      title,
      image_url,
      profile_name,
      profile_image_url,
      profile_description,
      characteristic_questions
    } = company_characteristic;
    const profile = {
      image_url: profile_image_url,
      name: profile_name,
      description: profile_description
    };
    return (
      <>
        <Container>
          <Title>{title}</Title>
          <img style={{ margin: "20px 0" }} alt="CompanyCharacteristic cover" src={image_url} />
          <CharacteristicQuestions characteristicQuestions={characteristic_questions} />
          
          <Profile profile={profile} />
          <div style={{ marginTop: "30px" }}>
            <RelatedFranchiseList companyCharacteristicId={id} />
          </div>
        </Container>
        <div style={{ padding: "50px 0", background: "#e6e6e6" }}>
          <BackNumberCompanyCharacteristicList companyCharacteristicId={id} />
        </div>
      </>
    ) 
  }
}

export default CompanyCharacteristicDetail;
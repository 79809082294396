export const initialFranchisesSearchType = data => {
  return {
    type: 'INITIAL_FRANCHISES_SEARCH_TYPES',
    data
  };
};

export const updatePostDetail = data => ({
  type: 'UPDATE_POST_DETAIL',
  data
})

export const updatePostsRelated = data => ({
  type: 'UPDATE_POSTS_RELATED',
  data
})

export const updateDataForm = data => {
  const type = `UPDATE_DATA_${data.reducer}`
  return {
    type,
    data
  };
};

export const updateZipcode = data => ({
  type: 'UPDATE_ZIPCODE',
  data
})

export const updateDesired= data => ({
  type: 'UPDATE_DESIRED',
  data
})

export const updateErrsForm = data => {
  const type = `UPDATE_ERRS_${data.reducer}`;
  return {
    type,
    data
  };
};

export const resetDataForm = data => {
  const type = `RESET_DATA_${data.reducer}`;
  return {
    type
  };
};

export const updateConditionSearch = data => ({
  type: 'UPDATE_SEARCH_CONDITION',
  data
})
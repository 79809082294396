export const fields = [
  {
    tag: "input",
    type: "text",
    name: "company_name",
    field_name: "会社名",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "name",
    field_name: "氏名",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "name_kata",
    field_name: "フリガナ",
    required: true
  },
  {
    tag: "component",
    name: "birthday",
    field_name: "生年月日",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "phone",
    field_name: "電話番号",
    placeholder: "000-000-000",
    required: true
  },
  {
    tag: "input",
    type: "email",
    name: "email",
    field_name: "メールアドレス",
    placeholder: "example@gmail.com",
    required: true
  },
  {
    tag: "input",
    type: "email",
    name: "email_confirm",
    field_name: "メールアドレス（確認用）",
    required: true
  },
  {
    tag: "component",
    name: "zipcode",
    field_name: "郵便番号",
    required: true
  },
  {
    tag: "component",
    name: "prefecture",
    field_name: "都道府県",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "city",
    field_name: "市区町村",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "address",
    field_name: "番地",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "apartment",
    field_name: "ビル・マンション",
    required: false
  },
  {
    tag: "textarea",
    name: "comment",
    field_name: "ご要望・その他",
    required: false
  },
  {
    tag: "component",
    name: "desired",
    field_name: "希望条件",
    note: "（複数選択可）",
    required: true
  }
]
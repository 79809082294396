import React from 'react';
import { Row, Col, Icon } from 'antd';
import { Link } from "react-router-dom";

import { PrimaryButton } from 'views/commons/styles';

class Information extends React.Component {
  render() {
    return (
      <Row style={{ margin: "10px auto 0" }}>

        <Col xs={12} style={{ paddingRight: "20px" }}>
          <Link to="/">
            <img src="/images/logo.png" alt="logo" />
          </Link>
        </Col>

        <Col xs={12}>
          <Row type="flex" justify="end">
            <Col xs={13}>
              <Row type="flex">
                <Col style={{padding:"10px 10px 0 0"}}>
                  <img src="/images/tell-icon.png" alt="icon" />
                </Col>
                <Col style={{color:"#333"}}>
                  <strong style={{fontSize:"30px"}}>03-6457-3186</strong><br />
                </Col>
                <Col style={{color:"#333"}}>
                  <div>10:00~17:00(平日のみ）</div>
                  <div style={{marginBottom:"10px"}}>メールでのお問合せは年中無休です。</div>
                </Col>
              </Row>
            </Col>
            <Col xs={11}>
              <PrimaryButton height="60px" width="100%" font_size="20px">
                <Link to="/free_request">
                  <Icon type="caret-right" />
                  無料資料請求
              </Link>
              </PrimaryButton>
            </Col>
          </Row>
        </Col>

      </Row>
    )
  }
}

export default Information;

import React from 'react';
import { Row } from 'antd';
import { Link } from "react-router-dom";
import MediaQuery from 'react-responsive';
import { Anchor, Icon, LinkTitle } from './styled';
import {Container} from 'views/commons/styles'

class Top extends React.Component {
  render() {
    return (
      <Container margin="20px auto">
        <MediaQuery maxWidth={964}>
          <div style={{ marginBottom: "15px" }}>
            フードフランチャイズナビ『麺』は、飲食業界の中でもラーメン、そば、うどん、パスタなどの麺業態に特化したフランチャイズＦＣ募集プラットフォームです。
          </div>
        </MediaQuery>
        <Row type="flex" justify="space-between" direction="row">
          <Anchor md={11} xs={11} direction="row">
            <Link to="/features">
              <MediaQuery minWidth={769}>
                <Icon src="/images/light-icon.png" alt="icon" />
              </MediaQuery>
              <LinkTitle>フード・フランチャイズ・ナビ麺の特徴</LinkTitle>
            </Link>
          </Anchor>
          <Anchor md={11} xs={11} direction="row">
            <Link to="/characteristics">
              <MediaQuery minWidth={769}>
                <Icon src="/images/book-icon.png" alt="icon" />
              </MediaQuery>
              <LinkTitle>本部紹介特集</LinkTitle>
            </Link>
          </Anchor>
        </Row>
      </Container>
    )
  }
}

export default Top;

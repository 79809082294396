import React from 'react';
import PropTypes from 'prop-types';

import CharacteristicQuestion from './CharacteristicQuestion';

class CharacteristicQuestions extends React.Component {
  static propTypes = {
    characteristicQuestions: PropTypes.array
  }

  render() {
    const { characteristicQuestions } = this.props;
    return (
      <div>
        {
          characteristicQuestions.map((characteristicQuestion, i) => {
            return <CharacteristicQuestion key={i} characteristicQuestion={characteristicQuestion} />
          })
        }
      </div>
    )
  }
}



export default CharacteristicQuestions;
import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import CheckboxSP from 'views/components/Checkbox/SP';
import {updateConditionSearch} from 'actions';
import { GroupButton, PrimaryButton } from 'views/commons/styles'
import { ModalStyle } from '../../SearchModal/SP/styled';


class SearchModalSP extends React.Component {

  state = { visible: false }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = (e) => {
    this.setState({
      visible: false,
    });
  }

  searchData = {};

  updateSearchData = (data) => {
    this.searchData = data;
  }

  searchFranchises = () => {
    this.closeModal();// close Modal
    this.props.onClose && this.props.onClose(); // Close Menu
    this.props.updateConditionSearch(this.searchData)
  }

  render() {
    const { search_types, modalStyle } = this.props;
    return (
      <>
        <div style={modalStyle} onClick={this.showModal}>
          {this.props.children}
        </div>
        <div >
          <ModalStyle
            className="searchModalSP"
            style={{ top: 0 }}
            maskStyle={{ background: "#333" }}
            bodyStyle={{ background: "#333", padding: "0" }}
            width="95%"
            title="フランチャイズを探す"
            visible={this.state.visible}
            footer={null}
            onCancel={this.closeModal}
          >
            <CheckboxSP
              search_types={search_types}
              passDataToParent={this.updateSearchData}
              isborder={false}
            />
            <GroupButton>
              <PrimaryButton
                onClick={this.searchFranchises}
                width="240px"
                margin="0 auto"
              >
                <Link to="/franchises/search">検　索</Link>
              </PrimaryButton>
              <PrimaryButton
                onClick={this.closeModal}
                background="#808080"
                color="#d2d2d2"
                width="150px"
              >
                × 閉じる
              </PrimaryButton>
            </GroupButton>
          </ModalStyle>
        </div>
      </>
    )
  }
}

export default connect(null, {updateConditionSearch})(SearchModalSP);


import React from 'react';
import { Link } from "react-router-dom";
import MediaQuery from 'react-responsive';
import { connect } from "react-redux";

import {updateConditionSearch} from 'actions';
import { Container } from 'views/commons/styles';
import { Type, Title, LinkGroup, QuickLink } from './styled';

class Footer extends React.Component {

  handleSearch = (type, id) => {
    const search_conditions = {
      [type]: [id]
    }
    this.props.updateConditionSearch(search_conditions)
  }

  render() {
    const { search_types } = this.props;
    const { ...new_search_types } = search_types;
    const types = Object.keys(new_search_types);
    return (
      <div style={{ borderTop: "solid 1px #f3f3f3" }}>
        <MediaQuery minWidth={965}>
          <Container style={{ padding: "20px 0" }}>
            {
              types.length ? types.map((type, i) => {
                return (
                  <Type key={i}>
                    <Title>{new_search_types[type].title}</Title>
                    <LinkGroup>
                      {
                        new_search_types[type].options.map((option, i) => {
                          const {id, name} = option;
                          return(
                            <QuickLink key={i} 
                              to="/franchises/search" 
                              onClick={() => this.handleSearch(type, id)}
                            >
                              {name}
                            </QuickLink>
                          )
                        })
                      }
                    </LinkGroup>
                  </Type>
                )
              }) : null
            }
            <div>
              <Link to="/policy" style={{ color: "#888", marginRight: "10px", fontWeight: "bold" }}>
                プライバシーポリシー
              </Link>
            </div>
          </Container>
        </MediaQuery>
        <div style={{ textAlign: "center", padding: "10px 0" }}>
          © 2019 Food value creation co.,ltd.
        </div>
      </div>
    )
  }
}

const mapStoreToProps = store => {
  return {
    search_types: store.search_types,
  };
};
export default connect(mapStoreToProps, {updateConditionSearch})(Footer);


import styled from 'styled-components';
import { Col, Row } from 'antd';


export const GroupButton = styled(Row)`
  display: flex;
  max-width: 500px;
  margin: auto;
  justify-content: space-around;
  flex-wrap: wrap;
  @media (max-width: 568px) {
    justify-content: center;
    .SubmitBtn{
      margin-bottom: 20px;
    }
  }
`;

export const BoxContainer = styled(Row)`
  border: solid 2px #ef8150;
  border-radius: 7px;
  background: #fffff8;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
`;

export const Box = styled(Col)`
  background: ${props => props.background || '#808080'};
  font-size: ${props => props.fontSize || '16px'};
  color: #fff;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 20px;
`;

export const Padded = styled(Col)`
  color: #333;
  font-weight: bold;
  font-size: 19px;
`;

export default null;

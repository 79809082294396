import React from 'react';
import { createArray } from 'helpers/CreateArrByNum';

import { CardWrapper, Card, CardHeader, CardBody, HorizontalChart } from './styled';

class Loading extends React.Component {

  render() {
    const { amount } = this.props;
    const cards = createArray(1, amount);
    return (
      cards.map(i => {
        return (
          <CardWrapper key={i} xs={12} lg={6}>
            <Card>
            <CardHeader />
            <CardBody>
              <HorizontalChart width="60%" height="15px" />
              <HorizontalChart width="60%" height="15px" />
              <HorizontalChart width="80%" height="25px" />
              <HorizontalChart width="50%" height="30px" />
            </CardBody>
          </Card>
        </CardWrapper>
        )
      })
    )
  }
}

export default Loading;

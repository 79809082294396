import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Collapse } from "antd";

export const StyledPanel = styled(Collapse.Panel)`
  background: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 24px;
  overflow: hidden;
  .ant-collapse-header{
    padding-left: 13px !important;
  }
`

export const Item = styled.div`
  background: #f2f2f2;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 30px;
`
export const Ques = styled(Row)`
  @media (min-width: 463px) {
    padding-bottom: 8px;
    margin-bottom: 15px;
  }
`
export const QuesIcon = styled(Col)`
  color: #fff;
  background: #e95513;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-right: 13px;
  font-weight: bold;
`
export const QuesText = styled(Col)`
  color: #e95513;
  font-size: 17px;
  font-weight: bold;
  @media (max-width: 465px) {
    max-width: 80%;
    font-size: 14px;
  }
  @media (max-width: 320px) {
    max-width: 75%;
  }
`
export const Ans = styled(Row)`
  border-top: solid 1px #fff;
  padding-top: 20px;
`
export const AnsIcon = styled(Col)`
  color: #e95513;
  background: #fff;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-right: 13px;
  font-weight: bold;
`
export const AnsText = styled(Col)`
  color: #000;
  font-size: 14px;
  line-height: 1.8em;
  white-space: pre-line !important;
  @media (max-width: 563px) {
    font-size: 13px;
  }
`

export default Item;

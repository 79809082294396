import React from 'react';
import { Row, Col, Select } from 'antd';
import { connect } from "react-redux";

import { updateDataForm } from 'actions';
import { rangeYears, rangeMonths, rangeDays, setMaxDay } from 'helpers/DateSelector';

const years = rangeYears.reverse();
const months = rangeMonths.map(month => `0${month}`.slice(-2));

class Birthday extends React.Component {

  state = {
    year: this.props.value && this.props.value.split("/")[0],
    month: this.props.value && this.props.value.split("/")[1],
    day: this.props.value && this.props.value.split("/")[2],
    maxDay: ""

  }

  componentWillReceiveProps(props) {
    const { year, month, day } = this.state;
    const prev_value = `${year}/${month}/${day}`;
    const next_value = props.value;
    if (next_value !== prev_value) {
      this.setState({
        year: props.value.split("/")[0],
        month: props.value.split("/")[1],
        day: props.value.split("/")[2],
        maxDay: ""
      });
    }
  }


  passData = () => {
    const { reducer } = this.props;
    const { year, month, day } = this.state;
    const date_string = `${year}/${month}/${day}`;
    this.props.updateDataForm({ birthday: date_string, reducer })
  }

  changeMaxDay = () => {
    let { year, month, day, maxDay } = this.state;
    if (month !== "" && year !== "") {
      maxDay = setMaxDay(month, year);
    }
    this.setState({
      maxDay,
      day: day > maxDay ? "" : day
    }, this.passData)
  };

  selectChange = (value, e) => {
    const name = e.props.name;
    this.setState({
      [name]: value
    }, this.changeMaxDay)
  }

  render() {
    const { year, month, day, maxDay } = this.state;
    const days = rangeDays(maxDay).map(day => `0${day}`.slice(-2))
    const Option = Select.Option;
    return (
      <Col xs={24} sm={24} md={15}>
        <Row>
          <Col xs={8}>
            <Select onChange={this.selectChange} value={year} style={{ width: 80, marginRight: "7px" }}>
              {
                years.map(year => <Option name="year" key={year}>{year}</Option>)
              }
            </Select>
            <span>年</span>
          </Col>
          <Col xs={8}>
            <Select onChange={this.selectChange} value={month} style={{ width: 70, marginRight: "7px" }}>
              {
                months.map(month => <Option name="month" key={month}>{month}</Option>)
              }
            </Select>
            <span>月</span>
          </Col>
          <Col xs={8}>
            <Select onChange={this.selectChange} value={day} style={{ width: 70, marginRight: "7px" }}>
              {
                days.map(day => <Option name="day" key={day}>{day}</Option>)
              }
            </Select>
            <span>日</span>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default connect(
  null,
  { updateDataForm }
)(Birthday);
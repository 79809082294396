import styled from 'styled-components'
import { Collapse, Checkbox } from 'antd';

export const StyledPanel = styled(Collapse.Panel)`
  border-radius: 5px;
  margin-bottom: 15px;
  background: ${props => props.background_panel || '#f2f2f2'};
  border: ${props => props.border_panel};
  .ant-collapse-header{
    padding: 10px !important;
    font-weight: bold;
  }
`
export const StyledCheckboxGroup = styled(Checkbox.Group)`
  label{
    display: block;
  }
`;

export default StyledPanel;

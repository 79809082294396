import React from 'react';
import {Container} from 'views/commons/styles';
import {StyledTopbar} from './styled';

class Topbar extends React.Component {
  render() {
    const {background_topbar, color_topbar, align_topbar} = this.props;
    return(
      <StyledTopbar 
        background_topbar={background_topbar} 
        color_topbar={color_topbar}
        align_topbar={align_topbar}
      >
        <Container>
          {this.props.children}
        </Container>
      </StyledTopbar>
    )
  }
}

export default Topbar;

import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import TopPage from 'views/pages/TopPage';
import FeaturesPage from 'views/pages/FeaturesPage';
import QaPage from 'views/pages/QaPage';
import PolicyPage from 'views/pages/PolicyPage';
import AboutPage from 'views/pages/AboutPage';
import FreeRequestPage from 'views/pages/FreeRequestPage';
import InquiryPage from 'views/pages/InquiryPage';
import CompanyRequestPage from 'views/pages/CompanyRequestPage';
import CharacteristicsPage from 'views/pages/CharacteristicsPage';

import FranchisesListPage from 'views/pages/FranchisesListPage';
import FranchisesDetailPage from 'views/pages/FranchisesDetailPage';
import FranchisesSearchPage from 'views/pages/FranchisesSearchPage';
import ResultSearchPage from 'views/pages/ResultSearchPage';
import NotFoundPage from 'views/pages/NotFoundPage';
import CompanyCharacteristicListPage from './views/pages/CompanyCharacteristicListPage';
import CompanyCharacteristicDetailPage from './views/pages/CompanyCharacteristicDetailPage';

const Main = () => (
  <main>
    <Switch>
      <Route exact path='/' component={TopPage} />
      <Route exact path='/features' component={FeaturesPage} />
      <Route exact path='/qa' component={QaPage} />
      <Route exact path='/policy' component={PolicyPage} />
      <Route exact path='/about' component={AboutPage} />
      <Route exact path='/characteristics' component={CharacteristicsPage} />

      <Route exact path='/free_request' component={FreeRequestPage} />

      <Route exact path='/inquiry' component={InquiryPage} />
      <Route exact path='/company_request' component={CompanyRequestPage} />

      <Route path='/franchises/page=:curpage' component={FranchisesListPage} />
      <Redirect exact from='/franchises' to='/franchises/page=1' />

      <Route exact path='/franchises/id=:id' component={FranchisesDetailPage} />
      <Route exact path='/franchises/setting' component={FranchisesSearchPage} />

      <Route exact path='/franchises/search/page=:curpage' component={ResultSearchPage} />
      <Redirect exact from='/franchises/search' to='/franchises/search/page=1' />

      <Route path='/company_characteristics/page=:curpage' component={CompanyCharacteristicListPage} />
      <Redirect exact from='/company_characteristics' to='/company_characteristics/page=1' />

      <Route exect path='/company_characteristics/id=:id' component={CompanyCharacteristicDetailPage} />

      <Route component={NotFoundPage} />
    </Switch>
  </main>
)

export default Main

import React from 'react';
import { withRouter } from "react-router-dom";
import {scroll} from 'helpers/SmoothScroll';

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      scroll()
    }
  }
  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
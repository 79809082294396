import styled, { keyframes } from 'styled-components';
import { Col } from "antd";

const loader_animate = keyframes`
  0%{
    transform: translate3d(-100%, 0, 0);
  }
  100%{
    transform: translate3d(100%, 0, 0);
  }
`

export const CardWrapper = styled(Col)`
  padding: 0 8px;
  margin-bottom: 20px;
`
export const Card = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 2px 1px rgba(0,0,0,.08), 0 -1px 3px 0 rgba(0,0,0,0.06);
  position: relative;
  border-radius: 2px;
  height: 280px;
  overflow: hidden;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: ${loader_animate} 0.6s linear infinite ;
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);

  }
`
export const CardHeader = styled.div`
  height: 110px;
  background-color: #ededed;
`
export const CardBody = styled.div`
  padding: 10px;
`
export const HorizontalChart = styled.div`
  background-color: #ededed;
  margin-bottom: 15px;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '45px'};
`
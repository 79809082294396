import {createArray} from 'helpers/CreateArrByNum';
const MIN_AGE = 15;
const MAX_AGE = 80;

const today = new Date();
const minYear = today.getUTCFullYear() - MAX_AGE;
const maxYear = today.getUTCFullYear() - MIN_AGE;

export const setMaxDay = (month, year) => new Date(year, month, 0).getDate();

export const rangeYears = createArray(minYear, maxYear);

// export const rangeMonths = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
export const rangeMonths = createArray(1, 12);

export const rangeDays = maxDay => createArray(1, maxDay);
export const transformSearchCondition = (input_data) => {
  let output_data = {
    "targets[]" : input_data.targets,
    "areas[]" : input_data.areas,
    "features[]" : input_data.features,
  };
  return output_data
}

export const transformDesired = (input_data) => {
  let output_data = {
    targets : input_data.targets.map(String),
    areas : input_data.areas.map(String),
    features : input_data.features.map(String)
  };
  return output_data
}
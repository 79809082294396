import styled from 'styled-components';
import { Row } from 'antd';

export const Content = styled.div`
  padding : ${props => props.padding || "20px 0"};
  font-size: 15px;
  color: #000000;
`
export const Frame = styled(Row)`
  border: solid 2px #e95513;
  border-radius: 5px;
  padding: 20px;
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 200px auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "image name-group"
    "image description";
  grid-column-gap: 20px;
  border: solid 2px #e95513;
  border-radius: 5px;
  padding: 20px;
  @media (max-width: 767px) {
    grid-template-columns: 30% auto;
    grid-template-areas:
      "image name-group"
      "description description";
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    align-items: center;
    padding: 15px;
  }
`
export const Image = styled.img`
  grid-area: image;
`

export const NameGroup = styled.div`
  grid-area: name-group;
`

export const Title = styled.h4`
  grid-area: title;
  font-weight: bold;
  color: #e95513
`

export const Name = styled.h3`
  grid-area: name;
  font-size: 125%;
  font-weight: bold;
`

export const Description = styled.p`
  grid-area: description;
  margin-bottom: 0;
  color: #000000;
`
import React from 'react';
import { Spin } from 'antd';
import CompanyCharacteristicDetail from 'views/pages/CompanyCharacteristicDetailPage/CompanyCharacteristicDetail';
import NotFoundPage from 'views/pages/NotFoundPage';
import { fetchLatestCompanyCharacteristic } from 'apis';


class SpecialFeaturesPage extends React.Component {
  state = {
    is_fetching: true,
    company_characteristic: ""
  }

  getLatestCompanyCharacteristic() {
    fetchLatestCompanyCharacteristic().then(data => {
      this.setState({
        is_fetching: false,
        company_characteristic: data.company_characteristic
      });
    })
  }

  componentDidMount() {
    this.getLatestCompanyCharacteristic();
  }

  render() {
    const {is_fetching, company_characteristic} = this.state;
    if (!is_fetching) {
      if (!company_characteristic) {
        return <NotFoundPage />
      } else if (Object.entries(company_characteristic).length === 0) {
        return null;
      }
    }
    return (
      is_fetching
      ? <Spin tip="Loading...">
          <div style={{ width: "100%", height: "100vh" }} />
        </Spin>
      : <CompanyCharacteristicDetail company_characteristic={company_characteristic} />
    )
  }
}

export default SpecialFeaturesPage;

import React from 'react';
import Topbar from 'views/components/Header/Topbar';
import Navbar from './Navbar';
import Information from './Information';
import { Container } from 'views/commons/styles';
import { StyledHeader } from './styled';

class Header extends React.Component {
  state = {
    isBoxShadow: false
  }
  handleScroll = (e) => {
    const distance_top_top = document.documentElement.scrollTop || document.body.scrollTop;
    this.setState({
      isBoxShadow: distance_top_top > 200
    })
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  render() {
    const { isBoxShadow } = this.state;
    return (
      <StyledHeader className={`${isBoxShadow ? 'styleBoxShadow' : null}`}>
        <Topbar background_topbar="#e95513" color_topbar="#fff">
          フードフランチャイズナビ『麺』は、飲食業界の中でもラーメン、そば、うどん、パスタなどの麺業態に特化したフランチャイズＦＣ募集プラットフォームです。
        </Topbar>
        <Container>
          <Navbar />
          <Information />
        </Container>
      </StyledHeader>
    )
  }
}

export default Header;

import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { StyledTag } from "views/commons/styles";

import { StyledGrid, FieldName, Value } from './styled';

class Grid extends React.Component {

  static propTypes = {
    field_name: PropTypes.string,
    display: PropTypes.string,
    justify: PropTypes.string,
    border_field: PropTypes.string,
    background_tag: PropTypes.string,
  }

  renderValue = () => {
    const { is_fetching, values, background_tag, color_tag } = this.props;
    if (is_fetching) {
      return (
        <Skeleton active title={false} paragraph={{ rows: 2 }} />
      )
    } else {
      return (
        Array.isArray(values)
          ? values.map((value, i) => <StyledTag key={i} background_tag={background_tag} color_tag={color_tag}>{value.name}</StyledTag>)
          : values
      )
    }
  }

  render() {
    const {
      field_name,
      display,
      justify,
      border_field,
      style_padding,
      class_name
    } = this.props;

    return (
      <StyledGrid border_field={border_field} type="flex">
        <FieldName xs={8} sm={6} md={5} display={display} justify={justify} style_padding={style_padding}>
          {field_name}
        </FieldName>
        <Value xs={16} sm={18} md={19} className={class_name}>
          {this.renderValue()}
        </Value>
      </StyledGrid>
    )
  }
}

export default Grid;


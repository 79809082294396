import React from 'react';
import PropTypes from 'prop-types';

import { Title, Content } from './styled';

class CharacteristicQuestion extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    question: PropTypes.string,
    answer: PropTypes.string
  }

  render() {
    const { characteristicQuestion } = this.props;
    const {
      question,
      answer
    } = characteristicQuestion;
    return (
      <>
        <Title>{question}</Title>
        <Content className="text-content">{answer}</Content>
      </>
    )
  }
}

export default CharacteristicQuestion;
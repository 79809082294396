import React from 'react';

import Field from 'views/components/Form';
import Instruction from 'views/components/Form/Instruction';
import { fields as FreeRequestFields } from 'constants/FreeRequestFields';
import { fields as FranchisesEntryFields } from 'constants/FranchisesEntryFields';
import { Container } from 'views/commons/styles';

class SecondStep extends React.Component {

  renderField = (field) => {
    const { data } = this.props; 
    const value = data[field.name];
    const name = field.name;
    if (value === "" || name.includes("confirm")) {
      return null
    }
    else {
      return (
        <Field
          step={2}
          key={name}
          align={`${(field.tag === "textarea" || field.name === "desired") ? "top" : "middle"}`}
          field={field}
          value={value}
        />
      )
    }
  };
  render() {
    const {franchise_id} = this.props;
    const fields = !franchise_id ? FreeRequestFields : FranchisesEntryFields;
    return (
      <>
        <Instruction>
          必要事項を記入し、下記の利用規約をご確認後、「規約に同意して確認画面へ」ボタンを押してください。
        </Instruction>
        <div style={{ background: "#f2f2f2", padding: "30px 0" }}>
          <Container>
            {
              fields.map(field => this.renderField(field))
            }
          </Container>
        </div>
      </>
    )
  }
}

export default SecondStep
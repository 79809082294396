export const page_defines = {
  "about" : {
    breadcrumb: "会社案内",
    page_title: "会社案内"
  },
  "policy" : {
    breadcrumb: "プライバシーポリシー",
    page_title: "プライバシーポリシー"
  },
  "features" : {
    breadcrumb: "フード・フランチャイズ・ナビ麺の特徴",
    page_title: "フード・フランチャイズ・ナビ麺の特徴"
  },
  "qa" : {
    breadcrumb: "よくある質問",
    page_title: "よくある質問"
  },
  "free_request" : {
    breadcrumb: "無料資料請求",
    page_title: "無料資料請求"
  },
  "franchises_entry" : {
    breadcrumb: "人材採用をお考えの企業様へ",
    page_title: "人材採用をお考えの企業様へ"
  },
  "inquiry" : {
    breadcrumb: "お問い合わせ",
    page_title: "お問い合わせ"
  },
  "company_request" : {
    breadcrumb: "掲載希望の方へ",
    page_title: "掲載希望の方へ"
  },
  "franchises/setting" : {
    breadcrumb: "フランチャイズを探す",
    page_title: "フランチャイズを探す"
  },
  "franchises" : {
    breadcrumb: "新着フランチャイズ一覧",
    page_title: "新着フランチャイズ一覧"
  },
  "characteristics" : {
    breadcrumb: "本部紹介特集",
    page_title: "本部紹介特集"
  },
  "company_characteristic" : {
    breadcrumb: "コラム・バックナンバー",
    page_title: "コラム・バックナンバー"
  },
  "not-found": {
    breadcrumb: "無効なURL",
    page_title: "無効なURL"
  }
}
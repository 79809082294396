import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Skeleton, Spin } from "antd";
import { connect } from "react-redux";
import Slider from './Slider';
import Grid from './Grid';
import Heading from 'views/components/Heading';
import Franchises from 'views/components/ListPost/Franchises';
import FreeRequestPage from 'views/pages/FreeRequestPage';
import NotFoundPage from 'views/pages/NotFoundPage';
import { scrollElement } from 'helpers/SmoothScroll';
import { fetchFranchisesDetail, fetchFranchisesRelated } from 'apis';
import {updatePostDetail} from 'actions';
import { Container, PrimaryButton, Tags, StyledTag } from 'views/commons/styles';
import { companyFields } from 'constants/CompanyFields';

class FranchisesDetailPage extends React.Component {

  constructor(props) {
    super(props);
    this.scrollToEntryForm = this.scrollToEntryForm.bind(this);
  }

  state = {
    is_fetching: true,
    franchisesDetail: "",
    franchisesRelated: []
  }

  async callAPI(id) {
    const franchisesDetail = await fetchFranchisesDetail(id).then(data => data.franchise);
    const franchisesRelated = await fetchFranchisesRelated(id, { per_page: 4 }).then(data => data.franchises);
    this.setState({
      is_fetching: false,
      franchisesDetail,
      franchisesRelated
    });
    this.props.updatePostDetail(franchisesDetail);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.callAPI(id)
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    const prev_id = prevProps.match.params.id;
    if (id !== prev_id) {
      this.setState({ is_fetching: true })
      this.callAPI(id)
    }
  }

  scrollToEntryForm(e) {
    e.preventDefault();
    scrollElement(this.refs.free_entry_form);
    return false;
  }

  renderCompany = () => {
    const { franchisesDetail } = this.state;
    const { company } = franchisesDetail;
    return Object.keys(companyFields).map((key, i) => {
      const class_name = (key === "business_contents" || key === "characteristics") ? "text-content" : "";
      return (
        <Grid style_padding="15px 20px" key={i} field_name={companyFields[key]} values={company[key]} class_name={class_name} />
      )}
    )
  }

  render() {
    const { is_fetching, franchisesDetail, franchisesRelated } = this.state;
    if(!is_fetching && !franchisesDetail){ // if fetching = true && has not return data
      return <NotFoundPage />
    }
    const {
      id,
      franchise_areas,
      title,
      description,
      franchise_images,
      recommendation_point,
      brand_name,
      merchant_members,
      working_time,
      fund,
      details,
      contract_period,
      royalty
    } = franchisesDetail;

    return (
      <Container>
        <div style={{ fontSize: "18px", margin: "10px 0 30px", color: "#000" }}>
          {
            is_fetching
              ? <Skeleton active title={false} paragraph={{ rows: 2 }} />
              : <>
                <b>{title}</b>
              </>
          }
        </div>
        <Row type="flex" justify="space-between">
          <Col xs={24} md={11} style={{ marginBottom: "20px" }}>
            {
              is_fetching ?
                <Spin tip="Loading...">
                  <img style={{ width: "100%" }} alt="slide" src="/images/no-photo.jpg" />
                </Spin>
                : <Slider images={franchise_images} />
            }
          </Col>
          <Col xs={24} md={12}>
            <div style={{ margin: "0 auto 30px", border: "solid 2px #e95513", padding: "20px", borderRadius: "7px" }}>
              <p style={{ color: "#e95513", fontSize: "20px" }}>おすすめポイント</p>
              <p style={{ color: "#000000", lineHeight: "2em" }} className="text-content">
                {recommendation_point}
              </p>
            </div>
          </Col>
          <PrimaryButton width="80%" display="block" margin="20px auto">
            <Link to="/free_request" onClick={this.scrollToEntryForm.bind(this)}>資料請求</Link>
          </PrimaryButton>
        </Row>
        <Heading>フランチャイズ詳細</Heading>
        <Row style={{ margin: "30px 0 30px" }}>
          <Grid style_padding="15px 20px" field_name="ブランド名" values={brand_name} />
          <Grid style_padding="15px 20px" field_name="加盟店舗数" values={merchant_members} />
          <Grid style_padding="15px 20px" field_name="エリア" values={
            <Tags style={{ margin: "0" }}>
              {
                is_fetching
                  ? <Skeleton active title={false}/>
                  : franchise_areas.map((franchise_area, i) => {
                    return <StyledTag key={i} border_tag="solid 1px #ccc">{franchise_area.name}</StyledTag>
                  })
              }
            </Tags>
          } />
          <Grid style_padding="15px 20px" field_name="営業時間" values={working_time} />
          <Grid style_padding="15px 20px" field_name="開業資金" values={fund} />
          <Grid style_padding="15px 20px" class_name="text-content" field_name="内訳" values={details} />
          <Grid style_padding="15px 20px" field_name="契約期間" values={contract_period} />
          <Grid style_padding="15px 20px" field_name="ロイヤリティ" values={royalty} />
          <Grid style_padding="15px 20px" class_name="text-content" field_name="フランチャイズ詳細" values={description} />
        </Row>
        <Heading>会社概要</Heading>
        <Row style={{ margin: "0 0 30px" }}>
          { is_fetching ? '' : this.renderCompany() }
        </Row>
        <FreeRequestPage ref="free_entry_form" history={this.props.history} franchise_id={id} />
        <div style={{ marginTop: "30px" }}>
          <Heading>この募集を見た人はこんな募集も見ています</Heading>
          <Franchises franchises={franchisesRelated} />
        </div>
      </Container>
    )
  }
}

export default connect(null, {updatePostDetail})(FranchisesDetailPage);



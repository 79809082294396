import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { BackTop } from 'antd';
import MediaQuery from 'react-responsive';
import { connect } from "react-redux";

import ScrollToTop from './ScrollToTop';
import HeaderPC from 'views/components/Header/PC';
import HeaderSP from 'views/components/Header/SP';
import Anchor from 'views/components/Anchor/Another';
import PageTitle from 'views/components/PageTitle';

import BreadcrumbComponent from 'views/components/Breadcrumb';
import QuickSearch from 'views/components/QuickSearch';
import FixedFooter from 'views/components/FixedFooter';
import Main from './routes';
import { initialFranchisesSearchType } from 'actions';

import './App.css';

class App extends React.Component {
  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/search_bar`)
      .then(response => response.json())
      .then(data => {
        this.props.initialFranchisesSearchType(data)
      });
  }

  render() {
    return (
      <BrowserRouter>
        <div>
          <MediaQuery minWidth={965}>
            <HeaderPC />
          </MediaQuery>
          <MediaQuery maxWidth={964}>
            <HeaderSP />
          </MediaQuery>
          <Anchor />
          <PageTitle />
          <BreadcrumbComponent />
          <ScrollToTop>
            <Main />
          </ScrollToTop>
          <QuickSearch />
          <FixedFooter />
          <BackTop />
        </div>
      </BrowserRouter>
    );
  }
}

export default connect(
  null,
  {initialFranchisesSearchType}
)(App);
import styled from 'styled-components';
import { Link } from "react-router-dom";

export const Type = styled.div`
  margin-bottom: 20px;
`
export const Title = styled.h3`
  font-weight: bold;
  color: #808080;
`
export const LinkGroup = styled.div`
  line-height: 1.8em;
  padding-left: 20px;
`

export const QuickLink = styled(Link)`
  margin-right: 15px;
  font-size: 15px;
  color: #808080;
  word-break: keep-all;
  white-space: pre !important;
`
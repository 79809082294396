import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Icon } from 'antd';

import SearchModal from 'views/components/SearchModal';
import { StyledLink, DrawerStyle } from './styled';
import { PrimaryButton } from 'views/commons/styles';

const modalStyle = {
  borderBottom: "solid 1px #e8e8e8",
  paddingBottom: "10px"
}

class Navbar extends React.Component {
  state = { visible: false, placement: 'left' };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  closeMenu = () => {
    this.setState({
      visible: false,
    });
  };

  renderLink = (to, label_link) => {
    return (
      <StyledLink to={to} onClick={this.closeMenu} >
        {label_link}
      </StyledLink>
    )
  }

  render() {
    return (
      <>
        <div style={{ cursor: "pointer" }} onClick={this.showDrawer}>
          <img src="/images/toggle-menu-icon.png" alt="toggle-menu" />
        </div>
        <DrawerStyle
          title="menu"
          width="100%"
          placement="left"
          closable={true}
          onClose={this.closeMenu}
          visible={this.state.visible}
          bodyStyle={{ padding: "10px" }}
        >
          <>
            <div style={{ borderBottom: "solid 1px #f3f3f3", paddingBottom: "15px" }}>
              <SearchModal modalStyle={modalStyle} onClose={this.closeMenu}>
                <img src="/images/search2-icon.png" alt="search-icon" />
                <b style={{ color: "#e95513", marginLeft: "15px" }}>フランチャイズを探す</b>
              </SearchModal>
            </div>

            {this.renderLink("/", "TOP")}
            {this.renderLink("/features", "フード・フランチャイズ・ナビ麺の特徴")}
            {this.renderLink("/characteristics", "本部紹介特集")}
          </>

          <PrimaryButton height="45px" display="block" margin="auto">
            <Link to="/free_request" onClick={this.closeMenu}>
              <Icon type="caret-right" />
              無料資料請求
            </Link>
          </PrimaryButton>

          <Row gutter={16} style={{ marginTop: "20px" }}>
            <Col xs={11} style={{ borderTop: "solid 1px #e8e8e8" }}>
              {this.renderLink("/qa", "よくあるご質問")}
              {this.renderLink("/inquiry", "お問い合わせ")}
              
            </Col>
            <Col xs={12} style={{ borderTop: "solid 1px #e8e8e8" }}>
              {this.renderLink("/company_request", "掲載希望の方へ")}
              {this.renderLink("/about", "会社案内")}
              {this.renderLink("/policy", "プライバシーポリシー")}
            </Col>
          </Row>
        </DrawerStyle>
      </>
    )
  }
}

export default Navbar;

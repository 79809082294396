import {
  getRequest,
  postRequest
} from './apiBase';


// Get Franchises List / Franchises New
export const fetchFranchises = (params) => {
  return getRequest({endPoint: "franchises", params})
}

// Get Franchise Detail
export const fetchFranchisesDetail = (id) => {
  return getRequest({endPoint: `franchises/${id}`})
}

// Get Franchises Related
export const fetchFranchisesRelated = (id, params) => {
  return getRequest({endPoint: `franchises/${id}/related`, params})
}

// Get Franchises Relative
export const getFranchisesRelative = (id, params) => {
  return getRequest({ endPoint : `franchises/${id}/relative-posts`, params })
}

// Get 4 Random Franchises
export const fetchRandomFranchises = () => {
  return getRequest({endPoint: `franchises/random`});
}

// Get 3 Banners
export const fetchBanners = () => {
  return getRequest({endPoint: `banners`});
}

// Submit Data Inquiry Page
export const postDataInquiry = (data) => {
  return postRequest({ endPoint : 'inquiries' , data})
};

// Submit Data Company Request Page
export const postDataCompanyRequest = (data) => {
  return postRequest({ endPoint : 'company_requests' , data})
}

// Submit Data Free Request Page
export const postDataFreeRequest= (data) => {
  return postRequest({ endPoint : 'free_requests' , data})
}

// Submit Data Franchises Entry Page
export const postDataFranchiseEntry = (data) => {
  return postRequest({ endPoint : 'franchise_entries' , data})
}

// Retrieve Company Characteristics
export const fetchCompanyCharacteristics = (params) => {
  return getRequest({endPoint: 'company_characteristics', params })
}

// Retrieve Latest Company Characteristic
export const fetchLatestCompanyCharacteristic = () => {
  return getRequest({endPoint: 'company_characteristics/latest'})
}

// Retrieve Company Characteristic Detail
export const fetchCompanyCharacteristicDetail = (id) => {
  return getRequest({endPoint: `company_characteristics/${id}`})
}

// Retrieve Back Number Company Characteristics
export const fetchBackNumberCompanyCharacteristics = (companyCharacteristicID, params) => {
  return getRequest({endPoint: `company_characteristics/${companyCharacteristicID}/back_number`, params})
}

// Retrieve Related Company Characteristic Franchises
export const fetchRelatedCompanyCharacteristicFranchises = (companyCharacteristicID, params) => {
  return getRequest({endPoint: `company_characteristics/${companyCharacteristicID}/franchises_related`, params});
}

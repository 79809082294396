import styled from 'styled-components'
import { Breadcrumb } from 'antd';

export const BreadcrumbItem = styled(Breadcrumb.Item)`
  color: #777;
  a{
    color: #777
  }
`

export default BreadcrumbItem;

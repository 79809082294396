import React from 'react';
import { connect } from "react-redux";

import Stepper from './Stepper';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import { fields as FreeRequestFields } from 'constants/FreeRequestFields';
import { fields as FranchisesEntryFields } from 'constants/FranchisesEntryFields';
import { success } from 'views/components/Success';
import { validate } from 'helpers/Validate';
import { transformDesired } from 'helpers/TransformData';
import { scrollElement } from 'helpers/SmoothScroll';
import { updateErrsForm, updateDataForm, resetDataForm } from 'actions';
import { postDataFreeRequest, postDataFranchiseEntry } from 'apis';
import { Container, PrimaryButton, GroupButton } from 'views/commons/styles';

class FormRequestPage extends React.Component {

  state = {
    curStep: 1,
  }

  resetForm = () => {
    localStorage.removeItem("requestFromData");
    this.props.resetDataForm({reducer: "FORM_REQUEST"})
  }

  prevStep = () => {
    this.setState({
      curStep: this.state.curStep - 1
    });
    scrollElement(this.refs.main_content);
  }

  nextStep = () => {
    const { franchise_id } = this.props;
    let { data } = this.props;
    const { curStep } = this.state;
    if (curStep === 1) {
      let errs = {};
      const fields = !franchise_id ? FreeRequestFields : FranchisesEntryFields;
      fields.forEach(field => {
        const { name } = field;
        let value = data[name];
        switch (name) {
          case 'email_confirm':
            value = {
              email: data.email,
              email_confirm: value
            };
            break;
          default:
            break
        }
        errs[name] = validate(field, value);
      });
      this.props.updateErrsForm({ errs, reducer: "FORM_REQUEST" });

      // Check if there are any errors in the field
      const name_errs = Object.keys(errs).filter(name => Boolean(errs[name]));
      if (name_errs.length) {
        const name_err = name_errs[0];
        const filed_err = this.refs.myFields.refs[name_err];
        scrollElement(filed_err);
      }else{
        this.setState({ curStep: this.state.curStep + 1 });
        scrollElement(this.refs.main_content)
      } 
    }
    else if (curStep === 2) {
      const { zipcode1, zipcode2, ...zipcode_transform } = data.zipcode;
      let {
        errs,
        reducer,
        email_confirm,
        zipcode,
        desired,
        ...dataSubmit
      } = data;
      if (!franchise_id) {
        const desired_transform = transformDesired(data.desired);
        dataSubmit = {
          ...dataSubmit,
          ...zipcode_transform,
          ...desired_transform
        };
        postDataFreeRequest(dataSubmit);
      } else {
        dataSubmit = {
          ...dataSubmit,
          ...zipcode_transform,
          franchise_id
        };
        postDataFranchiseEntry(dataSubmit);
      }
      success(() => {
        localStorage.setItem('requestFromData', JSON.stringify(data));
        this.props.history.push('/');
      });
    }
  }

  renderStep = (step) => {
    const { franchise_id } = this.props;
    let { data } = this.props;
    let storageData = localStorage.getItem('requestFromData');
    let storageDataFill = JSON.parse(storageData);

    if (storageData) {
      if (!data.is_filled) {
        storageDataFill.is_filled = true;
        this.props.updateDataForm(storageDataFill);
      }
    }
    switch (true) {
      case (step === 1):
        return <FirstStep ref="myFields" data={data} franchise_id={franchise_id} cleared={this.state.clearedForm} />
      case (step > 1):
        return <SecondStep data={data} franchise_id={franchise_id} />
      default:
        return null
    }
  }

  render() {
    const { curStep } = this.state;

    return (
      <>
        <Container>
          <Stepper curStep={curStep} />
        </Container>
        <div ref="main_content" style={{ background: "#f2f2f2", padding: " 0 0 30px 0" }}>
          <form id="freeRequestForm">
            {this.renderStep(curStep)}
            <GroupButton max_width="auto">
              <PrimaryButton
                disabled={curStep === 1}
                onClick={this.prevStep}
                width="120px"
                background="#b3b3b3">
                もどる
              </PrimaryButton>
              {
                curStep === 1 ? <PrimaryButton width="120px" background="#cccccc" onClick={this.resetForm}>リセット</PrimaryButton> : null
              }
              <PrimaryButton width="300px" onClick={this.nextStep}>
                {
                  curStep === 1 ? "利用規約に同意して確認画面へ" : "この内容で送信する"
                }
              </PrimaryButton>
            </GroupButton>
          </form>
        </div>
      </>
    )
  }
}
const mapStoreToProps = store => {
  return {
    data: store.FormRequestData
  };
};
export default connect(
  mapStoreToProps,
  { updateErrsForm, updateDataForm, resetDataForm}
)(FormRequestPage);
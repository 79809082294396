import styled from 'styled-components';
import { Row, Col } from 'antd';

export const List = styled.div`
  color: #333;
  font-size: 16px;
  @media (max-width: 465px) {
    font-size: 13px;
  }
`
export const Item = styled(Row)`
  padding: 10px 0;
  &:not(:last-child) {
    border-bottom: dotted 1px #ccc;
  }
`
export const Split = styled(Col)`
  text-align: center;
`
export const Label = styled(Col)`
  text-align: right;
`
export const Map = styled.div`
  height: 400px;
  padding: 10px 0;
  margin-bottom: 25px;
`

export default null;

import React from 'react';
import {Wrapper, Error, Title, Direction, ReturnTop, Social, StyledIcon} from './styled';

class NotFound extends React.Component {
  render() {
    return (

        <Wrapper>
          <Error>
            <h1>404</h1>
          </Error>
          <Title>ページが見つかりません</Title>

          <Direction>
            The page you are looking for might have been removed had its name changed or is temporarily unavailable. 
            <ReturnTop to="/">Return to homepage</ReturnTop>
          </Direction>

          <div>
            <Social href="#">
              <StyledIcon type="facebook" />
            </Social>
            <Social href="#">
              <StyledIcon type="twitter" />
            </Social>
            <Social href="#">
              <StyledIcon type="youtube" />
            </Social>
            <Social href="#">
              <StyledIcon type="google-plus" />
            </Social>
          </div>

        </Wrapper>

    )
  }
}

export default NotFound;

import React from 'react';
import { Pagination } from 'antd';

import Loading from 'views/components/Loading';
import Heading from 'views/components/Heading';
import Franchises from 'views/components/ListPost/Franchises';
import { fetchFranchises } from 'apis';
import { Container } from 'views/commons/styles';

class FranchisesListPage extends React.Component {

  state = {
    is_fetching: true,
    total_count: 0,
    current_page: 1,
    franchises: [],
  };

  getFranchises = (params) => {
    fetchFranchises({ page: params }).then((data) => {
      this.setState({
        is_fetching: false,
        franchises: data.franchises,
        total_count: data.meta.total_count,
        current_page: params
      })
    })
  }

  componentDidMount() {
    const curpage = parseInt(this.props.match.params.curpage);
    this.getFranchises(curpage)
  }

  changePage = (new_page) => {
    this.props.history.push(`/franchises/page=${new_page}`);
    this.setState({ is_fetching: true });
    this.getFranchises(new_page);
  }

  render() {
    const { is_fetching, franchises, current_page, total_count } = this.state;
    return (
      <Container>
        <Heading>フランチャイズ </Heading>
        {
          is_fetching
            ? <Loading amount={12} />
            : <>
              <Franchises franchises={franchises} />
              <Pagination
                total={total_count}
                defaultPageSize={franchises.length}
                showTotal={total => `全 ${total} 件`}
                pageSize={12}
                current={current_page}
                onChange={this.changePage}
                style={{ margin: "20px 0", textAlign: "right" }}
              />
            </>
        }
      </Container>
    )
  }
}

export default FranchisesListPage;

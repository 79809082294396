import React from 'react';
import { Row, Col, Button, Icon } from 'antd';
import { Link } from "react-router-dom";
import postal_code from 'japan-postal-code';
import { connect } from "react-redux";

import Input from './Input';
import { updateDataForm, updateZipcode, updateErrsForm } from 'actions';

import { validate } from 'helpers/Validate';

class Zipcode extends React.Component {

  state = {
    zipcode1: this.props.value.zipcode1 || "",
    zipcode2: this.props.value.zipcode2 || "",
    zipcode: ""
  }
  componentWillReceiveProps(props) {
    const { zipcode} = props.value;
    if (!zipcode) {
      this.setState({
        zipcode1: "",
        zipcode2: "",
        zipcode: ""
      });
    }
  }
  updateData = (data) => {
    const zipcode1 = data.zipcode1 !== undefined ? data.zipcode1 : this.state.zipcode1;
    const zipcode2 = data.zipcode2 !== undefined ? data.zipcode2 : this.state.zipcode2;
    const zipcode = zipcode1 + zipcode2;
    this.setState({
      zipcode1,
      zipcode2,
      zipcode
    }, () => this.props.updateZipcode(this.state))
  }

  handleAutoFill = () => {
    const { field, reducer } = this.props;
    const { name } = field;
    let errs = {};
    errs[name] = validate(field, this.state);

    if (!errs[name]) { // Neu dinh dang zipcode dung
      let data_auto_fill = {
        prefecture: "",
        city: "",
        address: ""
      }
      postal_code.get(this.state.zipcode, (location) => {
        data_auto_fill = {
          prefecture: location.prefecture,
          city: location.city,
          address: location.area,
          reducer
        }
        this.props.updateDataForm(data_auto_fill);
        this.props.updateErrsForm({
          errs: {
            prefecture: "",
            city: "",
            address: "",
          },
          reducer
        })
      });
      this.props.updateDataForm(data_auto_fill);
      this.props.updateErrsForm({ errs, reducer })
    }
    else { // Neu co loi
      this.props.updateErrsForm({ errs, reducer })
    }
  }

  render() {
    const { zipcode1, zipcode2 } = this.state;
    return (
      <Row gutter={16} type="flex" align="middle">
        <Col xs={6} sm={6} md={3}>
          <Input
            name="zipcode1"
            value={zipcode1}
            maxLength={3}
            passDataToParent={(data) => this.updateData(data)}
          />
        </Col>
        <Col xs={1} style={{ padding: 0, textAlign: "center" }}>
          <Icon type="minus" />
        </Col>
        <Col xs={7} sm={8} md={5}>
          <Input
            name="zipcode2"
            value={zipcode2}
            maxLength={4}
            passDataToParent={(data) => this.updateData(data)}
          />
        </Col>
        <Col xs={10} sm={9} md={7}>
          <Button style={{ background: "#666666", color: "#fff", height: "40px" }} onClick={this.handleAutoFill}>住所検索</Button>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Link to="/" style={{ color: "#e95513", textDecoration: "underline" }}>郵便番号がわからないときは？</Link>
        </Col>
      </Row>
    )
  }
}

export default connect(
  null,
  {
    updateDataForm,
    updateErrsForm,
    updateZipcode
  }
)(Zipcode);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import {updateDataForm} from 'actions';
import { StyledInput } from './styled';

class Input extends Component {

  static propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    type: "text"
  }

  handleChange = (e) => {
    const {reducer} = this.props;
    const name = e.target.name;
    const value = e.target.value;
    if(name.includes("zipcode")){
      this.props.passDataToParent({[name]: value})
    }else{
      this.props.updateDataForm({[name]: value, reducer})
    }
  }

  render() {
    const { name, type, value,  placeholder, maxLength } = this.props;
    return (
      <StyledInput
        type={type}
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={this.handleChange}
        value={value}
      />
    );
  }
}

export default connect(
  null,
  {updateDataForm}
)(Input);

import React from 'react';
import styled from 'styled-components';
import { colors } from 'views/commons/styles';

const HeadingStyle = styled.h1`
  text-transform: uppercase;
  color: ${colors.PRIMARY};
  border-bottom: solid 1px ${colors.PRIMARY};
  font-size: 1.4em;
  padding-bottom: 10px;
  font-weight: bold;
  margin-bottom: 25px;
`;


class Heading extends React.Component {
  render() {
    return(
      <HeadingStyle>
        {this.props.children}
      </HeadingStyle>
    )
  }
}

export default Heading;

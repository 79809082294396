import React from 'react';
import { Link, withRouter } from "react-router-dom";
import MediaQuery from 'react-responsive';

import { Container } from 'views/commons/styles';
import { Anchor, LinkTitle } from './styled';

class Another extends React.Component {
  render() {
    const { location } = this.props;
    return (
      <MediaQuery minWidth={769}>
        {
          location.pathname !== "/"
            ? <div style={{ background: "#e6e6e6", padding: "10px 0" }}>
              <Container type="flex" justify="space-between" style={{ alignItems: "stretch" }}>
                <Anchor xs={6} backgroundanchor="#fffff8">
                  <Link to="/franchises/setting">
                    <LinkTitle>フランチャイズを探す</LinkTitle>
                  </Link>
                </Anchor>
                <Anchor xs={7}>
                  <Link to="/features">
                    <LinkTitle>
                      フード・フランチャイズ・ナビ麺の特徴
                  </LinkTitle>
                  </Link>
                </Anchor>
                <Anchor xs={6}>
                  <Link to="/characteristics">
                    <LinkTitle>本部紹介特集</LinkTitle>
                  </Link>
                </Anchor>
              </Container>
            </div>
            : null
        }
      </MediaQuery>
    )
  }
}

export default withRouter(Another);

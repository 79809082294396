import React from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import { Grid, Image, NameGroup, Title, Name, Description } from './styled';

class Profile extends React.Component {
  static propTypes = {
    profile: PropTypes.object
  }

  render() {
    const {profile} = this.props;
    const {image_url, name, description} = profile;
    return (
      <Grid>
        <Image alt="Uer profile avatar" src={image_url} />
        <NameGroup>
          <Title>プロフィール</Title>
          {
            isMobileOnly
            ? <Name dangerouslySetInnerHTML={{__html: name.replace(/\s*／\s*/, '<br>')}}></Name>
            : <Name>{name}</Name>
          }
        </NameGroup>
        <Description>{description}</Description>
      </Grid>
    )
  }
}

export default Profile;
import React from "react";
import { Col, Button, Carousel, Checkbox, Icon } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {updateConditionSearch} from 'actions';
import {Container, PrimaryButton} from 'views/commons/styles';
import {  ModalStyle } from './styled';

class SearchModalPC extends React.Component {

  constructor(props) {
    super(props);
    this.carousel = React.createRef();
    this.state = {
      visible: false,
      index: '',
      search_conditions: {
        features: [],
        areas: [],
        targets: []
      }
    }
  };

  showModal = (i) => {
    this.setState({
      visible: true,
      index: i >= 2 ? -1 : i
    });
    setTimeout(() => {
      this.carousel.current.slick.slickGoTo(i);
    }, 50)
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleNextSlider = () => {
    this.carousel.current.slick.slickNext();
    let curIndex = this.state.index;
    curIndex = curIndex + 1;
    if (curIndex >= 2) {
      curIndex = -1
    }
    this.setState({
      index: curIndex
    })
  };

  handleCheckbox = (value, type) => {
    this.setState({
      search_conditions: {
        ...this.state.search_conditions,
        [type]: value
      }
    });
  }

  handleSearch = e => {
    const {search_conditions} = this.state;
    this.setState({
      visible: false
    });
    this.props.updateConditionSearch(search_conditions);
  };

  render() {
    const { search_types } = this.props;
    const types = Object.keys(search_types);
    const CheckboxGroup = Checkbox.Group;
    const { index } = this.state;
    return (
      <>
        {
          types.length ?
            <div style={{background: "#e6e6e6", padding: "8px 0"}}>
              <Container maxwidth="850px" type="flex" justify="space-between">
                <img src="/images/search-icon.png" alt="search-icon" />
                {
                  types.map((type, i) => {
                    return (
                      <Col key={i} xs={4} >
                        <Button onClick={() => this.showModal(i)}>
                          {search_types[type].title}
                          <Icon type="caret-down" />
                        </Button>
                      </Col>
                      
                    )
                  })
                }    
                <Col xs={4}>
                  <Link 
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        background: "#666666",
                        color: "#fff",
                        borderRadius: "2px"
                    }}
                    to="/franchises/setting"
                  >
                    検索
                  </Link>
                </Col>
              </Container>

              <ModalStyle
                width={900}
                visible={this.state.visible}
                onCancel={this.handleCancel}
                footer={
                  <div className="modal-footer">
                    <PrimaryButton key="next" width="250px" onClick={this.handleNextSlider}>
                      <Icon type="caret-right" />
                      {`${search_types[types[index + 1]].title}へ`}
                    </PrimaryButton>
                    <PrimaryButton background="#eabb25" width="100px" color="#fff">
                      <Link to="/franchises/search" onClick={this.handleSearch}>検索</Link>
                    </PrimaryButton>
                  </div>
                }

              >
                <Carousel dots="false" ref={this.carousel}>
                  {
                    types.map((type, i) => {
                      const options = search_types[type].options.map((option) => {
                        return { value: option["id"], label: option["name"] };
                      })
                      return (
                        <div key={i} >
                          <h1>{search_types[type].title}</h1>
                          <CheckboxGroup style={{ lineHeight: "2.3em" }}
                            options={options}
                            onChange={value => this.handleCheckbox(value, types[i])}
                          />
                        </div>
                      )
                    })
                  }
                </Carousel>
              </ModalStyle>
            </div>
            : null
        }

      </>
    );
  }
}

export default connect(null, {updateConditionSearch})(SearchModalPC);



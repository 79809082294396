import React from 'react';
import PropTypes from 'prop-types';
import { Row } from "antd";
import Franchise from './Franchise';
import { StyledPost } from '../styled';

class Franchises extends React.Component {
  static propTypes = {
    posts: PropTypes.array,
    category: PropTypes.string,
  }

  render() {
    const { franchises } = this.props;
    return (
      <Row type="flex">
        {
          franchises.map((franchise, i) => {
            return (
              <StyledPost key={i} xs={12} lg={6}>
                <Franchise franchise={franchise} />
              </StyledPost>
            );
          })
        }
      </Row>
    )
  }
}

export default Franchises;

import styled, { css } from 'styled-components';
import { Col, Row } from 'antd';

export const StyledField = styled(Row)`
  padding: 10px 0;
`
export const NameWrapper = styled(Col)`
  
  @media (min-width: 576px) {
    text-align: right;
  }
  @media (max-width: 576px) {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export const StyledFieldName = styled.span`
  color: #000000;
  font-size: 15px;
`

export const Require = styled.span`
  background: red;
  color: #fff;
  padding: 0 10px;
  margin-left: 15px;
  word-break: keep-all;
`;

export const Note = styled.span`
  padding: 5px 0;
  color: #808080;
  font-size: 12px;
  @media (min-width: 576px) {
    text-align: right;
    display: block;
  }
`

export const StyledInput = styled.input`
  width: 100%;
  max-width: 500px;
  border: solid 1px #ccc;
  padding: 7px;
  ${props => props.type === "number" && css`
    width: calc(84% - 10px);
    margin-right: 10px;
  `}
  @media (max-width: 576px) {
    border-radius: 8px;
  }
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  max-width: 500px;
  border: solid 1px #ccc;
  padding: 7px;
  min-height: 100px;
  @media (max-width: 576px) {
    border-radius: 8px;
  }
`;

export const ErrMsg = styled(Col)`
  color: red;
  padding-left: 10px;
  margin-top: 6px;
  font-size: 11px;
`;

// Second Step

export const Time = styled.span`
  margin-right: 15px;
`;

export const DesiredType = styled.div`
  margin-bottom: 15px;
`;

export const Option = styled.span`
  margin-right: 15px;
`;



export default null;

import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Field from 'views/components/Form';
import { fields } from 'constants/CompanyRequestFields';
import {success} from 'views/components/Success';
import { updateErrsForm } from 'actions';
import { validate } from 'helpers/Validate';
import { scrollElement } from 'helpers/SmoothScroll';
import {postDataCompanyRequest} from 'apis';

import { Container, PrimaryButton, DescriptionPage } from 'views/commons/styles';
import { BoxContainer, Box, GroupButton } from './styled';

class CompanyRequestPage extends React.Component {

  handleSubmit = () => {
    const {data} = this.props;
    let errs = {};
    fields.forEach(field => {
      const { name } = field;
      const value = data[name];
      errs[name] = validate(field, value);
    });
    this.props.updateErrsForm({errs, reducer:"COMPANY_REQUEST"});

    // Check if there are any errors in the field
    const name_errs = Object.keys(errs).filter(name => Boolean(errs[name]));
    if (name_errs.length) {
      const name_err = name_errs[0];
      const field_err = this.refs[name_err];
      scrollElement(field_err);
    }
    else{
      const { 
        errs, 
        reducer,
        ...dataSubmit 
      } = data;
      postDataCompanyRequest(dataSubmit);
      success(() => this.props.history.push('/'));
    }
  };

  render() {
    const {data} = this.props;
    const {errs} = data;
    return (
      <>
        <Container>
          <DescriptionPage>
            フードフランチャイズナビ『麺』は、らーめん、そば、うどん、パスタなどの麺業態に特化したフランチャイズ募集のプラットフォームです。
            <br/>弊社グループが有する独自のネットワークとノウハウで貴社のＦＣ，ボランタリー加盟開発を低コストでお手伝いいたします。
          </DescriptionPage>
          <div style={{ color: "#000", lineHeight: "1.7em", marginBottom: "20px" }}>
            【弊社グループの強み】
            <br />・国内約7000店の飲食店のユーザーを抱えるＩＴ企業です。
            <br />・Ｗｅｂソリューションチームを有する企業です。
            <br />・全国ネットワークを有する不動産チームを構成しています。
            <br />・飲食の経営を行っています。
            <br />・厨房機器（中古も含む）、ＰＯＳ、券売機など（中古含む）の提供も可能です。などなど、約30年飲食業界携わってきた経験とノウハウ、独自のネットワークで貴社の発展に貢献いたします！　そして、このフードフランチャイズナビ『麺』は、麺業界に特化することで差別化を図り、なおかつ、日本一低価格で、日本一効果のあるフランチャイズＦＣ募集のプラットフォームを目指しています。お気軽のお問い合わせください。
          </div>
          <BoxContainer>
            <Box background="#ef8150" fontSize="24px">低価格でのＦＣ募集が可能です。詳しくは、お問い合わせください。</Box>
          </BoxContainer>
        </Container>

        <div style={{ background: "#f2f2f2", padding: "30px 0" }}>
          <Container>
            {
              fields.map((field, i) => {
                return (
                  <Field
                    reducer="COMPANY_REQUEST"
                    key={i}
                    align={`${field.tag === "textarea" ? "top" : "middle"}`}
                    field={field}
                    err={errs[field.name]}
                    ref={field.name}
                  />
                )
              })
            }
            <div style={{ textAlign: "center", margin: "20px auto" }}>
              <Link to="/policy">個人情報保護方針</Link>
              <b>をご確認の上、「利用規約に同意して確認画面へ」ボタンを押して次の画面へ進んでください。</b>
            </div>
            <GroupButton>
              <PrimaryButton onClick={this.handleSubmit} className="SubmitBtn" width="360px">
                送　信
              </PrimaryButton>
              <PrimaryButton className="ResetBtn" width="120px" background="#b3b3b3">
                リセット
              </PrimaryButton>
            </GroupButton>
          </Container>
        </div >
      </>

    )
  }
}

const mapStoreToProps = store => {
  return {
    data: store.CompanyRequestData
  };
};

export default connect(
  mapStoreToProps,
  {updateErrsForm},
)(CompanyRequestPage);


import styled from 'styled-components'
import { Carousel, Icon } from 'antd';

export const StyledCarousel = styled(Carousel)`
  .slick-dots{
    position: absolute;
    bottom: -20px !important;
    button{
      width: 10px !important;
      height: 10px !important;
      border-radius: 100% !important;
      background: #868686 !important
    }
    @media (max-width: 768px) {
      display: none !important
    }
  }
  
`
export const StyledPrev = styled(Icon)`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  font-size: 29px;
  color: #868686;
`
export const StyledNext = styled(StyledPrev)`
  right: 0
`
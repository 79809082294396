export const fields = [
  {
    tag: "input",
    type: "text",
    name: "company_name",
    field_name: "会社名",
    placeholder: "例） 株式会社フード・フランチャイズ・ナビ麺",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "name",
    field_name: "氏名",
    placeholder: "例） 山田　太郎",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "name_kata",
    field_name: "フリガナ",
    placeholder: "例） ヤマダ　タロウ",
    required: true
  },
  {
    tag: "component",
    name: "birthday",
    field_name: "生年月日",
    required: true
  },
  {
    tag: "input",
    type: "text",
    name: "phone",
    field_name: "電話番号",
    placeholder: "000-000-000",
    required: false
  },
  {
    tag: "input",
    type: "email",
    name: "email",
    field_name: "メールアドレス",
    placeholder: "example@gmail.com",
    required: true
  },
  {
    tag: "input",
    type: "email",
    name: "email_confirm",
    field_name: "メールアドレス（確認用）",
    required: true
  },
  {
    tag: "textarea",
    name: "content",
    field_name: "お問い合わせ内容",
    required: true
  }
]

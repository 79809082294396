import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { connect } from "react-redux";

import FieldName from './FieldName';
import Input from './Input';
import TextArea from './TextArea';
import Birthday from './Birthday';
import Zipcode from './Zipcode';
import Prefecture from './Prefecture';
import Desired from './Desired';

import { StyledField, ErrMsg, Time, DesiredType, Option } from './styled';

class Field extends Component {
  static propTypes = {
    align: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string
  }

  checkBoxLabel = (type, id) => {
    const { search_types } = this.props;
    if (type === "areas") {
      return id === "" ? "全国" : search_types[type].options[id].name
    } else {
      return search_types[type].options[id - 1].name
    }
  }

  renderFirstStep = (field, value, reducer) => {
    switch (field.tag) {
      case "input":
        return <Input
          type={field.type}
          name={field.name}
          value={value}
          placeholder={field.placeholder}
          reducer={reducer}
        />
      case "textarea":
        return <TextArea
          name={field.name}
          value={value}
          placeholder={field.placeholder}
          reducer={reducer}
        />
      case "component":
        switch (field.name) {
          case "birthday":
            return <Birthday
              name={field.name}
              value={value}
              reducer={reducer}
            />
          case "zipcode":
            return <Zipcode
              field={field}
              value={value}
              reducer={reducer}
              autoFillDataToParent={(data) => this.props.autoFillDataToParent(data)} />
          case "prefecture":
            return <Prefecture
              name={field.name}
              value={value}
              reducer={reducer}
            />
          case "desired":
            return <Desired
              name={field.name}
              desireds={value}
            />
          default:
            break;
        }
        break;
      default:
        return null
    }
  }

  renderSecondStep = (field, value) => {
    const {search_types} = this.props;
    switch (field.name) {
      case "birthday":
        const year = value.split("/")[0];
        const month = value.split("/")[1];
        const day = value.split("/")[2]
        return (
          <>
            <Time>{`${year}年`}</Time>
            <Time>{`${month}月`}</Time>
            <Time>{`${day}日`}</Time>
          </>
        )
      case "zipcode":
        const {zipcode1, zipcode2} = value;
        return <>{`${zipcode1} - ${zipcode2}`}</>
      case "desired":
        const types = Object.keys(value);
        return(
          types.map(type => {
            const ids= value[type];
            return(
              ids.length
              ? <DesiredType key={type}>
                  <h3>{search_types[type].title}</h3>
                  <div>
                    {
                      ids.map(id => {
                        return(
                          <Option key={id}>
                            {
                              this.checkBoxLabel(type, id)
                            }
                          </Option>
                        )
                      })
                    }
                  </div>
              </DesiredType>
              : null
            )
          })
        )
      default:
        return value
    }
  }

  render() {
    const { step, field, value, err, align, reducer } = this.props;
    return (
      <StyledField>
        <Row gutter={15} type="flex" align={align}>
          <Col xs={24} sm={10} md={7}>
            <FieldName require={field.required} note={field.note}>
              {field.field_name}
            </FieldName>
          </Col>
          <Col xs={24} sm={14} md={17}>
            {step === 2 ? this.renderSecondStep(field, value) : this.renderFirstStep(field, value, reducer)}
          </Col>
        </Row>
        <ErrMsg xs={{ offset: 0 }} sm={{ offset: 9 }} md={{ offset: 7 }}>
          {err}
        </ErrMsg>
      </StyledField>
    );
  }
}

const mapStoreToProps = store => {
  return {
    search_types: store.search_types,
  };
};
export default connect(mapStoreToProps)(Field);
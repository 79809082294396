export const identifySearchType = (search_types, search_conditions) => {
  let page_title;
  // Get all type in "search_types"
  const types = Object.keys(search_types);

  // Get all type is selected in "search_conditions"
  const types_selected = types.filter(type => search_conditions[type].length);


  if (types_selected.length === 1) { // if only one type selected
    const type_selected = types_selected[0];
    const ids_selected = search_conditions[type_selected];

    if (ids_selected.length === 1) { // if type selected is has only one id
      const id_selected = ids_selected[0];
      const options_selected = search_types[type_selected].options;
      const option_selected = options_selected.filter(option => option.id === id_selected);
      page_title = option_selected[0].name;
    }
    else { // if type selected has more than one id
      //page_title = search_types[type_selected].title
      page_title = "検索結果"
    }
  }
  else { // if not type selected or has more than one type selected
    page_title = "検索結果"
  };

  return page_title
}

import React from "react";
import PropTypes from 'prop-types';
import { Card } from "antd";
import { Link } from "react-router-dom";
import { PrimaryButton } from 'views/commons/styles';
import { CardImgGrid } from "../styled";

class CompanyCharacteristic extends React.Component {

  static propTypes = {
    franchise: PropTypes.object,
    id: PropTypes.number,
    image_url: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    tags: PropTypes.array,
    update: PropTypes.string,
    topic: PropTypes.string,
  }

  static defaultProps = {
    tags: []
  }

  render() {
    const { Meta } = Card;
    const { companyCharacteristic } = this.props;   
    const {
      id,
      title,
      image_url
    } = companyCharacteristic;

    return (
      <CardImgGrid
        cover={<div className="img-grid"><img alt="CompanyCharacteristic cover" src={image_url} /></div>}
        bodyStyle={{ padding: "10px 10px 25px" }}
        style={{ background: "#e6e6e6", border: "solid 1px #cccccc" }}
        actions={[
          <PrimaryButton fontSize="14px" margin="auto" display="block">
            <Link to={`/company_characteristics/id=${id}`}>
              詳細を見る
            </Link>
          </PrimaryButton>
        ]}
      >
        <Meta style={{margin: "0 auto 10px"}} title={title.slice(0, 10)} />
      </CardImgGrid>
    );
  }
}

export default CompanyCharacteristic;





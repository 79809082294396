import React from 'react';
import { Col } from 'antd';

import Heading from 'views/components/Heading';

import { Container, DescriptionPage } from 'views/commons/styles';
import { List, Item, Label, Split, Map } from './styled';
import BusinessContent from './BusinessContent';

const items = [
  {
    name: "会社名",
    value: "株式会社フードバリュークリエイション"
  },
  {
    name: "本社所在地",
    value: "〒169-0051 東京都新宿区西早稲田2-20-15 高田馬場アクセスビル3F"
  },
  {
    name: "電話番号",
    value: "03-6457-3186"
  },
  {
    name: "FAX番号",
    value: "03-6457-3187"
  },
  {
    name: "代表取締役",
    value: "明田　典久"
  },
  {
    name: "事業内容",
    value: <BusinessContent />
  },
  {
    name: "許認可",
    value: "厚生労働大臣認可 有料職業紹介事業"
  },
  {
    name: "e-mail",
    value: "web@food-vc.com"
  }
]

class ContactPage extends React.Component {
  render() {
    return (
      <Container>
        <DescriptionPage>
          『飲食業界の価値向上に貢献する！』
        </DescriptionPage>
        <List>
          {
            items.map((item, i) => {
              return(
                <Item key={i}>
                  <Label xs ={5} sm={3}>{item.name}</Label>
                  <Split xs={2} sm={2}>:</Split>
                  <Col xs ={17} sm={19}>{item.value}</Col>
                </Item>
              )
            })
          }
        </List>
        <br/>
        <>
          <Heading>アクセスマップ</Heading>
          <Map>
            <iframe 
              title="株式会社フードバリュークリエイション"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.660345117882!2d139.70770971525957!3d35.70997508018762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d3d3a5d7ec9%3A0xde7253b1d97ea8f7!2z44CSMTY5LTAwNTEg5p2x5Lqs6YO95paw5a6_5Yy66KW_5pep56iy55Sw77yS5LiB55uu77yS77yQ4oiS77yR77yV!5e0!3m2!1sja!2sjp!4v1577091556874!5m2!1sja!2sjp" 
              style={{ width: "100%",  height :"100%", border: "0"}}>
            </iframe>
          </Map>
        </>
      </Container>
    )
  }
}

export default ContactPage;

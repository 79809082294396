import moment from 'moment';
import {arrToConvert} from './ConvertArray';

const checkEmpty = (name, value) => {
  switch (name) {
    case 'zipcode':
      value = Object.values(value)[2];
      break;
    case 'email_confirm':
      value = Object.values(value)[1];
      break;
    case 'password_confirm':
      value = Object.values(value)[1];
      break;
    case 'desired':
      value = arrToConvert(value);
      break;
    default:
      break
  }

  const value_type = typeof value;

  if(value_type === "string"){
    value = value.replace(/\s/g, '');
  }
  return !(value.length)
}

const validPhone = (value) => {
  const phone_format = /^(?:\d{10}|\d{3}-\d{3}-\d{4}|\d{2}-\d{4}-\d{4}|\d{4}-\d{2}-\d{4}|\d{3}-\d{4}-\d{4})$/i;
  const is_valid_phone = phone_format.test(value);
  return is_valid_phone
};

const validEmail = (value) => {
  const mail_format = /\S+@\S+\.\S+/i;
  const is_valid_email = mail_format.test(value);
  return is_valid_email
};

const validEmailConfirm = (value) => {
  const email = Object.values(value)[0];
  const email_confirm = Object.values(value)[1];
  return (validEmail(email) && (email === email_confirm));
};

const validPassword = (value) => {
  const password_format = /^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z]{8,32}$/i;
  const is_valid_password = password_format.test(value);
  return is_valid_password
};

const validPasswordConfirm = (value) => {
  const password = Object.values(value)[0];
  const password_confirm = Object.values(value)[1];
  return (validPassword(password) && (password === password_confirm));
};

const validDate = (value) => {
  const is_valid_date = moment(value, "YYYY/MM/DD", true).isValid();
  return is_valid_date
};

const validZipcode = (value) => {
  const { zipcode1, zipcode2 } = value;
  const zipcode1_format = /^\d{3}$/;
  const zipcode2_format = /^\d{4}$/;
  const isValidZipcode1 = zipcode1_format.test(zipcode1);
  const isValidZipcode2 = zipcode2_format.test(zipcode2);
  return (isValidZipcode1 && isValidZipcode2)
};

const logErr = (field_name, is_valid, err_type) => {
  const err = is_valid ? null : `${field_name} ${err_type}`
  return err
}

export const validate = (field, value) => {
  const {name,field_name, required} = field;
  if (required) {
    const is_empty = checkEmpty(name, value);
    if (is_empty) {
      return `${field_name} が入力されていません。`
    }
    else {
      let err_type = "が正しくありません";
      let valid_field;
      switch (name) {
        case 'phone':
          valid_field = validPhone(value);
          break;
        case 'birthday':
          valid_field = validDate(value);
          break;
        case 'zipcode':
          valid_field = validZipcode(value);
          break;
        case 'email':
          valid_field = validEmail(value);
          break;
        case 'email_confirm':
          valid_field = validEmailConfirm(value);
          break;
        case 'password':
          valid_field = validPassword(value);
          break;
        case 'password_confirm':
          valid_field = validPasswordConfirm(value);
          break;
        default:
          valid_field = true
          break
      }
      return logErr(field_name, valid_field, err_type)
    }
  }
}
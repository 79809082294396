import React from 'react';
import { Spin } from 'antd';
import { connect } from "react-redux";

import NotFoundPage from 'views/pages/NotFoundPage';
import CompanyCharacteristicDetail from './CompanyCharacteristicDetail';
import { fetchCompanyCharacteristicDetail } from 'apis';
import {updatePostDetail} from 'actions';

class CompanyCharacteristicDetailPage extends React.Component {
  state = {
    is_fetching: true,
    company_characteristic: ""
  }

  getCompanyCharacteristicDetail(id) {
    fetchCompanyCharacteristicDetail(id).then(data => {
      this.setState({
        is_fetching: false,
        company_characteristic: data.company_characteristic
      })
      this.props.updatePostDetail(data.company_characteristic);
    })
  }

  componentDidMount() {
    const {id} = this.props.match.params;
    this.getCompanyCharacteristicDetail(id);
  }

  componentDidUpdate(prevProps) {
    const {id} = this.props.match.params;
    const prev_id = prevProps.match.params.id;
    if (id !== prev_id) {
      this.setState({ is_fetching: true });
      this.getCompanyCharacteristicDetail(id);
    }
  }

  render() {
    const {is_fetching, company_characteristic} = this.state;
    if (!is_fetching && !company_characteristic) {
      return <NotFoundPage />
    }
    return (
      is_fetching
      ? <Spin tip="Loading...">
          <div style={{ width: "100%", height: "100vh" }} />
        </Spin>
      : <CompanyCharacteristicDetail company_characteristic={company_characteristic} />
    )
  }
}

export default connect(null, {updatePostDetail})(CompanyCharacteristicDetailPage);